import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Button,
  Tooltip,
  Typography,
  IconButton,Stack
} from "@mui/material";
import Page from "src/components/Page";
import Edit from "@mui/icons-material/Edit";
import { Link, useNavigate,  useParams } from "react-router-dom";
import NormalCard from "src/components/Cards/NormalCard";
import DashboardLogs from "../ServiceOrders/ServiceOrderLogs";
import ContactInfo from "src/components/Tables/ContactInfo";
import { userService } from "src/_services/users";
import Chip from "@mui/material/Chip";
import Documents from "./Dcuments";
import ReactSpinner from "src/components/ReactSpinner/ReactSpinner";
import { stubFalse } from "lodash";
import SnackBar from "src/components/SnackBar/SnackBar";
import { connect } from "react-redux";
import { logoutSuccess, switchRole } from "src/store/bugs";
import DematAccountData from "src/containers/Profile/DematAccount";
import BankAccountData from "../Profile/BankAccount";
import KycDocumentData from "../Profile/KycDocument";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TagModal from "../../components/Modals/TagModal";
import ModalCentered from "src/components/Modals/ModalCentered";
import UpdateStatus from "./UpdateStatus";
import PreApprovedStatus from "./PreApprovedStatus";

const PREFIX = 'UserDetails';

const classes = {
  root: `${PREFIX}-root`,
  hdrlblbtn: `${PREFIX}-hdrlblbtn`,
  individual: `${PREFIX}-individual`,
  labelname: `${PREFIX}-labelname`,
  headername: `${PREFIX}-headername`,
  details: `${PREFIX}-details`,
  tooltip: `${PREFIX}-tooltip`,
  detail: `${PREFIX}-detail`,
  formgrgroup: `${PREFIX}-formgrgroup`,
  formSection: `${PREFIX}-formSection`,
  btndocuments: `${PREFIX}-btndocuments`,
  btnprimary: `${PREFIX}-btnprimary`,
  reactSpinner: `${PREFIX}-reactSpinner`,
  iconpdf: `${PREFIX}-iconpdf`,
  editbutton: `${PREFIX}-editbutton`,
  first_letter: `${PREFIX}-first_letter`,
  iconClass: `${PREFIX}-iconClass`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.hdrlblbtn}`]: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(0),
    alignItems: "center",
    marginBottom: "1%",
  },

  [`& .${classes.individual}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.labelname}`]: {
    [theme.breakpoints.up(576)]: {
      width: "22%",
    },
    width: "32%",
    textAlign: "end",
    fontWeight: "500",
  },

  [`& .${classes.headername}`]: {
    [theme.breakpoints.up(576)]: {
      width: "62%",
    },
    width: "32%",
    borderBottom: "1px solid #1c1c1c",
    textAlign: "center",
    fontWeight: "600",
  },

  [`& .${classes.details}`]: {
    // width: "68%",
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.tooltip}`]: {
    cursor: "copy",
  },

  [`& .${classes.detail}`]: {
    marginTop: "1rem",
    display: "flex",
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.formgrgroup}`]: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.formSection}`]: {
    display: "flex",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: "80%",
    margin: "2rem auto",
  },

  [`& .${classes.btndocuments}`]: {
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
  },

  [`& .${classes.btnprimary}`]: {
    borderRadius: "12px 12px 12px 0px",
    marginRight: theme.spacing(2),
    textTransform: "capitalize",
  },

  [`& .${classes.reactSpinner}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#f4f6f870",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.iconpdf}`]: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },

  [`& .${classes.editbutton}`]: {
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },

  [`& .${classes.first_letter}`]: {
    fontSize: "0.87rem",
    marginTop: "3px",
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },

  [`& .${classes.iconClass}`]: {
    cursor: "pointer",
  }
}));


const ShowUserDetails = ({ authReducer }, props) => {

  const navigate = useNavigate();

  let params = useParams();
  // let userID = params.id
  const role = authReducer.auth
    ? authReducer.auth.user !== null && authReducer.auth.user.default_role_code
    : null;

  const [userBasicInfo, setUserBasicInfo] = useState();
  // const [userAddress, setUserAddress] = useState([]);
  const [contactData, setContactData] = useState([]);
  // const [dematData, setDematData] = useState([])
  const [statusModal, setStatusModal] = useState({
    open:false,
    status:'',
    reason:''
  })
  const [spinner, setSpinner] = useState(true);
  const [userID, setUserID] = useState(params.id);
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState("");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [severity, setSeverity] = React.useState("");
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );

  const requestId = userBasicInfo?.request_id;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  // const goToTransactionHistory = () => {
  //   navigate(`/app/client-service-orders/${userID}`, {
  //     state: { userID: userID, userName: name, requestId: requestId },
  //   });
  // };
  const getBasicInfo = () => {
    userService.getUserDetails(userID).then(async (res) => {
      if (res) {
        if (res.status === 200) {
         let address = await userService.getUserAddress(userID)
         if(address.data.length > 0){
            const fullAddress = 
                (address.data[0].address1 ? `${address.data[0].address1} ` : '') +
                (address.data[0].address2 ? `${address.data[0].address2} ` : '') +
                (address.data[0].city ? `${address.data[0].city} ` : '') +
                (address.data[0].state ? `${address.data[0].state} ` : '') +
                (address.data[0].country ? `${address.data[0].country} ` : '') +
                (address.data[0].zipcode ? `${address.data[0].zipcode}` : '');
            res.data[0].address = fullAddress
          }
          setUserBasicInfo(res.data[0]);
          setSpinner(stubFalse);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setSpinner(stubFalse);
            setOpenSessionExpiredModal(true);
          } else {
            setSpinner(stubFalse);
            setSeverity("error");
            setSnackbarTitle("The user doesn't exist!");
            setOpenSnackBar(true);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (userID) {
      getBasicInfo();
    }
    userService.getContactInfoAccounts(userID).then((res) => {
      if (res) {
        if (res.status === 200) {
          setContactData(res.data);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });
  }, [userID]);

  const handleEditBtn = () => {
    navigate("/app/user-create?mode=edit", {
      replace: true,
      state: { userID: userID, userName: name, requestId: requestId },
    });
  };

  const name = userBasicInfo
    ? `${userBasicInfo.first_name} ${userBasicInfo.last_name}`
    : null;

  const [copy, setCopy] = useState(false);

  const onHover = (value) => {
    setCopy(true);
    navigator.clipboard.writeText(value);
  };

  const onLeave = () => {
    setCopy(false);
  };

  const CustomTooltip =(({label,value})=>{
    return(
      <div className={classes.formgrgroup}>
      <label className={classes.labelname}>{label}</label>

      <Tooltip placement={label ==='Address' ? "left" : "right"}
        className={classes.tooltip}
        title={copy ? "Copied" : "Click to Copy"}
      >
        <p
          onClick={() => {
            onHover(value);
          }}
          onMouseLeave={onLeave}
          style={{width:label ==='Address' ? "68%" :''}}
          className={classes.details}
        >
          {value}
        </p>
      </Tooltip>
    </div>
    )
  })

  const submitHandle=((value)=>{
    const data={
      ...value,id:userID,status:value.status ==='Verified' ? 1:value.status ==='Pending' ? 2 : 3
    }
    userService.updateVerifiedStatus(data).then((res)=>{
      if (res.status === 200) {
        setSeverity("success");
        setSnackbarTitle("Updated successfully");
        setOpenSnackBar(true);
        getBasicInfo()
        setStatusModal({
          open:false,
          status:'',
          reason:''
        })
      } else {
        if (res.status === 401 && res.data.name === "TokenExpiredError") {
          setSpinner(stubFalse);
          setOpenSessionExpiredModal(true);
        } else {
          setSpinner(stubFalse);
          setSeverity("error");
          setSnackbarTitle("Failed to update");
          setOpenSnackBar(true);
        }
      }
    })
  })

  const handlePreApprovedStatus=((value)=>{
    const data={
      ...value,id:userID,status:value.preApprovedStatus  ==="Yes" ? true:false,range:value.range ? value.range : null
    }
    userService.updatePreApprovedtatus(data).then((res)=>{
      if (res.status === 200) {
        setSeverity("success");
        setSnackbarTitle("Updated successfully");
        setOpenSnackBar(true);
        getBasicInfo()
        setStatusModal({
          open:false,
          status:'',
          reason:''
        })
      } else {
        if (res.status === 401 && res.data.name === "TokenExpiredError") {
          setSpinner(stubFalse);
          setOpenSessionExpiredModal(true);
        } else {
          setSpinner(stubFalse);
          setSeverity("error");
          setSnackbarTitle("Failed to update");
          setOpenSnackBar(true);
        }
      }
    })  })

  const handleModal=(()=>{
    setStatusModal({
      open:true,
      status:userBasicInfo.status,
      reason:userBasicInfo.rejected_reason,
      type:'userStatus'
    })
  })

  const handleStatusModal =(()=>{
    setStatusModal({
      open:true,
      status:userBasicInfo.prepaid_approved,
      range:userBasicInfo.prepaid_approved_range,
      type:'preApprovedStatus'
    })
  })

  return (
  <Root>
    <Page title="Unlistedkart | UserdetailsPage">
      <div className={classes.hdrlblbtn}>
        <Typography className={classes.heder} variant="h4">
          Users Details
        </Typography>
       {role ==='ADMIN_VIEWER' ? null : <Button
          className={classes.editbutton}
          variant="contained"
          color="primary"
          size="medium"
          onClick={handleEditBtn}
        >
          Edit
        </Button>}
      </div>
      <NormalCard title="Individual / Company">
        {spinner && (
          <div className={classes.reactSpinner}>
            <ReactSpinner />
          </div>
        )}
        <div className={classes.individual}>
          {userBasicInfo && userBasicInfo.code === "INDIVIDUAL" ? (
            <CustomTooltip  label={"User Name"} value ={userBasicInfo && `${userBasicInfo.first_name} ${userBasicInfo.last_name}`}/>
                        ) : (
            <>
            <CustomTooltip  label={"Company Name"} value ={userBasicInfo && userBasicInfo.company_name}/>
            <CustomTooltip  label={"Company Reg. No."} value ={userBasicInfo && userBasicInfo?.company_registered_no}/>
            <CustomTooltip  label={"GSTIN"} value ={userBasicInfo && userBasicInfo?.gstin}/>
            </>
          )}
            <CustomTooltip  label={"Email"} value ={userBasicInfo && userBasicInfo.email}/>
            <CustomTooltip  label={"Phone"} value ={userBasicInfo && userBasicInfo.contact_no}/>
            <CustomTooltip  label={"PAN"} value ={userBasicInfo && userBasicInfo?.pan?.toUpperCase()}/>
            {userBasicInfo?.user_role.some((item)=> item.code ==="OPERATION" || item.code ==="ADMIN") && <CustomTooltip  label={"Support Ticket Assign Access"} value ={userBasicInfo && userBasicInfo.ticket_access ? "Yes" : "No"} />}
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Type</label>
            <p className={classes.details}>
              {userBasicInfo && userBasicInfo.description}
            </p>
          </div>
          {role === "INVESTER_CLIENT" ? (
            <></>
          ) : (
            <>
              <div className={classes.formgrgroup}>
                <label className={classes.labelname}>Reporting</label>
                <p className={classes.details}>
                  {/* <Button 
                                onClick={()=>setUserID(userBasicInfo.related_reporting_id)} 
                                color="primary"
                                href
                            >
                                {userBasicInfo && `${userBasicInfo.reporting_user_first_name} ${userBasicInfo.reporting_user_last_name}`}
                            </Button> */}

                  <Link
                    component="button"
                    variant="subtitle1"
                    color="primary"
                    onClick={() =>
                      setUserID(userBasicInfo.related_reporting_id)
                    }
                    to={`/app/user-details/${userBasicInfo &&
                      userBasicInfo.related_reporting_id}`}
                  >
                    {userBasicInfo &&
                      `${
                        userBasicInfo.reporting_user_name !== null
                          ? userBasicInfo.reporting_user_name
                          : ""
                      }`}
                  </Link>
                </p>
              </div>
              <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Payment pre-approved</label>
            <p className={classes.details}>
            <Stack direction={'row'}>
              <Chip sx={{background:userBasicInfo?.prepaid_approved ? "#314B8F" : '#f96464',color:'#fff', fontWeight:'bold'}} size="small" label={userBasicInfo && userBasicInfo.prepaid_approved ? "Yes": "No"}/> 
                 {(role ==="ADMIN" || role ==="OPERATION" )&& <IconButton disableRipple onClick={handleStatusModal}>
                    <Edit sx={{ mt:-1}}/>
                  </IconButton>}
                </Stack>
            </p>
            </div>
              {/* <CustomTooltip  label={"Payment pre-approved"} value ={userBasicInfo && userBasicInfo.prepaid_approved ? "Yes": "No"}/> */}
              {userBasicInfo?.prepaid_approved ? <CustomTooltip  label={"Payment pre-approved range"} value ={userBasicInfo && userBasicInfo.prepaid_approved_range}/> : null}

              <div className={classes.formgrgroup}>
                <label className={classes.labelname}>Roles</label>
                <p className={classes.details}>
                  {userBasicInfo &&
                    userBasicInfo.user_role.map((data, i) => (
                      <Chip
                        key={i}
                        size="small"
                        label={data.description}
                        style={{ marginRight: 5 }}
                      />
                    ))}
                </p>
              </div>

              {/* <div className={classes.formgrgroup}>
                <label className={classes.labelname}>Access</label>
                <p className={classes.details}>
                  {userBasicInfo && (
                    <React.Fragment>
                      <Chip
                        disabled
                        variant="outlined"
                        size="small"
                        label={"Login"}
                        color="primary"
                        onDelete={handleDelete}
                        style={{ marginRight: 5 }}
                        deleteIcon={
                          userBasicInfo && userBasicInfo.required_login ? (
                            <DoneIcon />
                          ) : (
                            true
                          )
                        }
                      />
                      <Chip
                        disabled
                        variant="outlined"
                        size="small"
                        label={"Inventory Update"}
                        color="primary"
                        onDelete={handleDelete}
                        style={{ marginRight: 5 }}
                        deleteIcon={
                          userBasicInfo &&
                          userBasicInfo.required_price_update ? (
                            <DoneIcon />
                          ) : (
                            true
                          )
                        }
                      />
                    </React.Fragment>
                  )}
                </p>
              </div> */}
            </>
          )}
          {/* <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Reg. Address</label>
            <p className={classes.details}> */}
          {/* {(userAddress.user_id !== null ? userAddress.user_id : "" )} */}
          {/* {(userAddress.address_id !== null ? userAddress.address_id+"," : "" )}  */}
          {/* {userAddress &&
              userAddress.address1 &&
              userAddress.address1 !== null
                ? userAddress.address1 + ", "
                : ""} */}
          {/* {userAddress &&
              userAddress.address2 &&
              userAddress.address2 !== null
                ? userAddress.address2 + ", "
                : ""} */}
          {/* {userAddress && userAddress.city && userAddress.city !== null
                ? userAddress.city + ","
                : ""} */}
          {/* {userAddress &&
              userAddress.zipcode &&
              userAddress.zipcode !== null
                ? userAddress.zipcode + ", "
                : ""} */}
          {/* {userAddress && userAddress.state && userAddress.state !== null
                ? userAddress.state + ", "
                : ""} */}
          {/* {userAddress &&
              userAddress.country &&
              userAddress.country !== null
                ? userAddress.country
                : ""} */}
          {/* </p>
          </div> */}

          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>User Status</label>
            <p className={classes.details}>
              {userBasicInfo && (
                <React.Fragment>
                  {userBasicInfo.is_active === true ? (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={"Active"}
                      color="primary"

                      // style={{ marginRight: 5 ,color:"#00C914",borderColor:"#00C914"}}
                    />
                  ) : (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={"Deleted"}
                      style={{
                        marginRight: 5,
                        color: "#f44336",
                        borderColor: "#f44336",
                      }}
                    />
                  )}
                </React.Fragment>
              )}
            </p>
          </div>
            <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Verified Status</label>
            <p className={classes.details}>
            <Stack direction={'row'}>
              <Chip sx={{background:userBasicInfo?.status===2 ? "#ffaf34" : userBasicInfo?.status===1 ? "#314B8F" : '#f96464',color:'#fff', fontWeight:'bold'}} size="small" label={userBasicInfo?.status ===1 ?'Verified' :userBasicInfo?.status ===2 ?'Pending': <Tooltip  title={userBasicInfo?.rejected_reason}>Rejected</Tooltip>}/> 
                 {(role ==="ADMIN" || role ==="OPERATION" )&& <IconButton disableRipple onClick={handleModal}>
                    <Edit sx={{ mt:-1}}/>
                  </IconButton>}
                </Stack>
            </p>

          </div>

          <CustomTooltip  label={"Address"} value ={userBasicInfo && userBasicInfo?.address}/> 
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>KYC Status</label>
            <p className={classes.details}>
              {userBasicInfo && (
                <Stack>
                  <Typography className={classes.first_letter}>
                    {userBasicInfo.ulk_kyc_status}
                  </Typography>
                </Stack>
              )}
            </p>
          </div>
          {/* <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Geo Location</label>
            <p className={classes.details}>
              {userBasicInfo && (
                <React.Fragment>
                  <Typography className={classes.first_letter}>
                    {userBasicInfo.location_geo_url === null ||
                    userBasicInfo.location_geo_url === undefined
                      ? "Not Found"
                      : userBasicInfo.location_geo_url}
                  </Typography>
                </React.Fragment>
              )}
            </p>
          </div> */}
          {/* <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Transaction History</label>
            <p className={classes.details}> */}
          {/* {userBasicInfo && (
                <React.Fragment>
                  <Grid container className={classes.iconClass}>
                    <Tooltip title="Transcation history">
                      <HistoryIcon
                        onClick={goToTransactionHistory}
                      ></HistoryIcon>
                    </Tooltip>
                  </Grid>
                </React.Fragment>
              )} */}
          {/* </p> */}
          {/* <p className={classes.details}>
              {userBasicInfo && (
                <React.Fragment>
                    <Button ></Button>
                </React.Fragment>
              )}
            </p> */}
          {/* </div> */}
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Tags</label>
            <p className={classes.detail}>
              {userBasicInfo?.tags && userBasicInfo.tags[0] !== null && (
                <>
                  {userBasicInfo.tags.slice(0, 5).map((value, i) => {
                    return (
                      <Chip
                        key={i}
                        style={{ marginRight: 5, marginTop: "-1.1rem" }}
                        label={value}
                        variant="outlined"
                        size="small"
                        color="primary"
                      />
                    );
                  })}
                  {userBasicInfo.tags.length > 5 && (
                    <Chip
                      onClick={() => {
                        setOpen(true);
                        setTags(userBasicInfo.tags ? userBasicInfo.tags : []);
                      }}
                      style={{
                        marginRight: 5,
                        marginTop: "-1.1rem",
                        backgroundColor: "#1A76D2",
                        color: "#fff",
                      }}
                      label="more..."
                      size="small"
                    />
                  )}
                </>
              )}
              <Tooltip title="Edit tags" placement="right">
                <IconButton
                  style={{
                    marginTop: "-1.5rem",
                    padding: "6px",
                    marginLeft: "-0.25rem",
                  }}
                  disabled={role === 'ADMIN_VIEWER'}
                  className={classes.iconbtn}
                  onClick={() => {
                    setOpen(true);
                    setTags(
                      userBasicInfo.tags && userBasicInfo.tags[0] !== null
                        ? userBasicInfo.tags
                        : []
                    );
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </p>
          </div>
      
          <div className={classes.formSection}>
            <label className={classes.headername}>Partner Details</label>
          </div>        
          <CustomTooltip  label={"Partner Code"} value ={userBasicInfo && userBasicInfo?.related_partner_code}/>
          <CustomTooltip  label={"Partner Representative"} value ={userBasicInfo && userBasicInfo?.partner_representative_name}/>


     {userBasicInfo?.user_role.some(obj =>  obj.description === "Partner's Client" || obj.description === "Partner's RM")
                && 
          <CustomTooltip  label={"Partner Admin"} value ={userBasicInfo && userBasicInfo?.admin_name}/>
}
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Partner Logo</label>
            {/* <Tooltip
                  className={classes.tooltip}
                  title={copy ? "Copied" : "Click to Copy"}
                >
                  <p className={classes.details}
                    onClick={() => {
                      onHover(userBasicInfo?.partner_representative_name);
                    }}
                    onMouseLeave={onLeave}>
                    {userBasicInfo &&
                      userBasicInfo.partner_representative_name}

                  </p>
                  </Tooltip> */}
            {userBasicInfo?.partner_logo ? (
              <img
              style={{maxWidth:'300px'}}
                className={classes.details}
                src={userBasicInfo?.partner_logo}
                alt="partnerLogo"
              />
            ) : null}
          </div>
        </div>
      </NormalCard>
      {role === "INVESTER_CLIENT" ? (
        <></>
      ) : (
        <NormalCard title="Contact Info">
          <ContactInfo data={contactData} edit={false} />
        </NormalCard>
      )}

      {/* <DematAccountDetails data={dematData} makePrimaryHandler={makePrimaryDemat} edit={false} /> */}
      <DematAccountData userID={userID} edit={false} />

      {/* <NormalCard title="Bank Account Details">
                <BankAccountDetails data={bankAccountData} edit={false} />
            </NormalCard>             */}

      <BankAccountData userID={userID} edit={false} />

      <KycDocumentData
        userID={userID}
        edit={false}
        userName={name}
        requestId={requestId}
      />

      <Documents data={userID} pan={userBasicInfo && userBasicInfo?.pan?.toUpperCase()} edit={false} />
      <DashboardLogs id={userID} typeId={1}  title="User History"/>
      <SnackBar
        open={openSnackBar}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <TagModal
        getData={getBasicInfo}
        open={open}
        setOpen={setOpen}
        userId={userID}
        userName={name}
        tagName={tagName}
        setTagName={setTagName}
        tags={tags}
        setTags={setTags}
        setSnackbarTitle={setSnackbarTitle}
        setOpenSessionExpiredModal={setOpenSessionExpiredModal}
        setSeverity={setSeverity}
        setOpenSnackBar={setOpenSnackBar}
      />

      <SessionExpiredModal open={openSessionExpiredModal} />
      <ModalCentered
                title={"Update verified status"}
                open={statusModal.open}
                toggle={() => setStatusModal({
                  open:false,
                  status:'',
                  reason:''
                })}
                children={
                  statusModal.type ==="preApprovedStatus" ? 
                  <PreApprovedStatus
                  data={statusModal}
                  formSubmit={handlePreApprovedStatus}
                  />:
                    <UpdateStatus
                    data={statusModal}
                        formSubmit={submitHandle}
                    />
                }
            />
    </Page>
    </Root>
  );
};
const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
  switchRole: (role) => dispatch(switchRole(role)),
});

export default connect(mapStateToProps, dispatchToProps)(ShowUserDetails);
