import React, {useState,useEffect} from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import { Container, TextField, Grid, FormControlLabel, Radio, RadioGroup, FormLabel, FormControl } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import loadingSpinner from "src/images/loading-spinner.gif";
import Autocomplete from "@mui/material/Autocomplete";
import ModalCentered from "src/components/Modals/ModalCentered";
import { getLookUpDataService } from "src/_services/lookUp";

const PREFIX = "EmpanelmentDocument";

const classes = {
  container: `${PREFIX}-container`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "10%",
  },
  [`& .${classes.popDownBtn}`]: {
    // borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
    textTransform: "none",
  },
  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

const EmpanelmentDocument = (props) => {
  const loading =props.spinner
  const [show, setShow] = React.useState(false);
  const [client,setClient] = useState([]);
  const initValues = {
    document_type: {
      id: 1,
      name: "Individual",
    },
    effective_date: "",
    execution_date: "",
    client_name: "",
    userType:"1",
    client_pan: "",
    business_nature: "",
    authorized_person: "",
    client_email: "",
    client_address: "",
    downloadType:'1'
  };

  const documentType = [
    {
      id: 1,
      name: "Individual",
    },
    {
      id: 2,
      name: "Non Individual",
    },
  ];

  const downloadFile = (fileURL,values) => {
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `${values.client_name?.user_name ? values.client_name?.user_name : "Sample"}_${values.document_type.name}_Empanelment.pdf`;
    link.click();
  };

  useEffect(()=>{
    getLookUpDataService
    .getLookUpUserForDealterm("CHANNEL_PARTNER,INVESTER_CLIENT")
    .then((res) => {
      if (res) {
        if (res.status === 200) {
          let item = res.data.map((items) => {
              return {
                code: items.user_code,
                id: items.id,
                pan:items.user_pan ?items.user_pan.toUpperCase() : '',
                email:items.email,
                user_name:items.user_name,
                lable: `${items.user_name} ( ${items.user_pan ?items.user_pan.toUpperCase() : items.user_code.toUpperCase()} )  ${items.verified_status ===1 ? '✅' : '❌'}`,
              };
          });
          setClient(item);
        } 
      }
    })
  },[])

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          effective_date: Yup.string().required("Required"),
          execution_date: Yup.string().required("Required"),
          client_name: Yup.string().required("Required"),
          client_email:Yup.string().when("document_type", {
            is: (item)=> item.id ===1,
            then:  Yup.string()
          .nullable()
          .email("Enter a valid email")
          .required("Email is required"),
          otherwise: Yup.string().nullable(),
          }),

          client_address: Yup.string().required("Required"),
          client_pan:Yup.string().when("document_type", {
            is: (item)=> item.id ===1,
            then:  Yup.string()
            .max(10, "max is 10 character")
            .required("Required")
            .matches("[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}", "PAN is invalid"),
            otherwise: Yup.string().nullable(),
          }),
          
          business_nature:Yup.string().when("document_type", {
            is: (item)=> item.id ===2,
            then:  Yup.string().required("Required"),
            otherwise: Yup.string().nullable(),
          }),
          authorized_person:Yup.string().when("document_type", {
            is: (item)=> item.id ===2,
            then:  Yup.string().required("Required"),
            otherwise: Yup.string().nullable(),
          })
        })}
        onSubmit={(values) => {
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container spacing={2}>
                <Grid  item xs={12}>
                <FormControl
                        component="fieldset"
                        error={Boolean(touched.userType && errors.userType)}
                      >
                        <FormLabel component="legend">Download as</FormLabel>
                        <RadioGroup 
                          row
                          aria-label="downloadType"
                          name="downloadType"
                          value={values.downloadType}
                          id="downloadType"
                          onBlur={handleBlur}
                          onChange={(e)=>{
                            setFieldValue('downloadType',e.target.value)
                            if(e.target.value ==='2'){
                              setFieldValue('client_name','______________________________')
                              setFieldValue('client_pan','______________________')
                              setFieldValue('effective_date','______________________')
                              setFieldValue('execution_date','______________________')
                              setFieldValue('client_email','______________________________')
                              setFieldValue('client_address','________________________________')
                              setFieldValue('authorized_person','________________________________')
                              setFieldValue('business_nature','________________________________')

                            }else{
                              setFieldValue('client_name','')
                              setFieldValue('client_pan','')
                              setFieldValue('effective_date','')
                              setFieldValue('execution_date','')
                              setFieldValue('client_email','')
                              setFieldValue('client_address','')
                              setFieldValue('authorized_person','')
                              setFieldValue('business_nature','')
                            }
                          }}
                        >
                          <FormControlLabel
                            value={"1"}
                            control={<Radio color="primary" />}
                            label="With Client Details"
                          />
                          <FormControlLabel
                            value={"2"}
                            control={<Radio color="primary" />}
                            label="Sample"
                          />
                          </RadioGroup>
                      </FormControl>
                      <ErrorFocus />
                </Grid>
                  <Grid item xs={12}>
                  <Autocomplete
                    //   id={name}
                    disableClearable
                    name="document_type"
                    value={values.document_type}
                    options={documentType}
                    getOptionLabel={(option) => option.name || ""}
                    onChange={async (e, value) => {
                      if (value !== null) {
                        setFieldValue("document_type", value);
                      } else {
                        setFieldValue("document_type", "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Document Type"}
                        helperText={touched.document_type && errors.document_type}
                        error={Boolean(touched.document_type && errors.document_type)}
                      />
                    )}
                  />
                  <ErrorFocus />
                </Grid>
                {
                  values.downloadType ==='1' ?
                  <>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="execution_date"
                    label="Execution Date"
                    type="date"
                    name="execution_date"
                    value={values.execution_date}
                    error={Boolean(touched.execution_date && errors.execution_date)}
                    helperText={touched.execution_date && errors.execution_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="effective_date"
                    label="Effective Date"
                    type="date"
                    name="effective_date"
                    error={Boolean(touched.effective_date && errors.effective_date)}
                    helperText={touched.effective_date && errors.effective_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // InputProps={{
                    //   inputProps: {
                    //     max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                    //   },
                    // }}
                    value={values.effective_date}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl
                        component="fieldset"
                        error={Boolean(touched.userType && errors.userType)}
                      >
                        <FormLabel component="legend">User Type</FormLabel>
                        <RadioGroup 
                          row
                          aria-label="userType"
                          name="userType"
                          value={values.userType}
                          id="userType"
                          onBlur={handleBlur}
                          onChange={(e)=>{
                            setFieldValue('userType',e.target.value)
                            setFieldValue('client_name','')
                            setFieldValue('client_pan','')
                            setFieldValue('client_email','')
                            setFieldValue('client_address','')
                          }}
                        >
                          <FormControlLabel
                            value={"1"}
                            control={<Radio color="primary" />}
                            label="Listed"
                          />
                          <FormControlLabel
                            value={"2"}
                            control={<Radio color="primary" />}
                            label="Not Listed"
                          />
                          </RadioGroup>
                      </FormControl>
                      <ErrorFocus />
                    </Grid>
             <Grid item xs={12} sm={12} >
             { values.userType ==="1" ?
                   <Autocomplete
                    //   id={name}
                    disableClearable
                    name="client_name"
                    value={values.client_name}
                    options={client}
                    getOptionLabel={(option) => option.lable || ""}
                    onChange={async (e, value) => {
                      if (value !== null) {
                        setFieldValue("client_name", value);
                        setFieldValue("client_pan", value?.pan);
                        setFieldValue("client_email", value?.email);
                      } else {
                        setFieldValue("client_name", "");
                        setFieldValue("client_pan", "");
                        setFieldValue("client_email", "");
                        setFieldValue("client_address", "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Client Name"}
                        helperText={touched.client_name && errors.client_name}
                        error={Boolean(touched.client_name && errors.client_name)}
                      />
                    )}
                  />:
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="client_name"
                    label="Client Name"
                    name="client_name"
                    value={values.client_name}
                    error={Boolean(touched.client_name && errors.client_name)}
                    helperText={touched.client_name && errors.client_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                }
                  <ErrorFocus />
                </Grid>

               {values.document_type.id  ===1 ? 
              (<>
               <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                    variant="outlined"
                    fullWidth
                    id="client_pan"
                    label="Client Pan"
                    name="client_pan"
                    value={values.client_pan?.toUpperCase()}
                    error={Boolean(touched.client_pan && errors.client_pan)}
                    helperText={touched.client_pan && errors.client_pan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="client_email"
                    label="Client Email"
                    name="client_email"
                    value={values.client_email}
                    error={Boolean(touched.client_email && errors.client_email)}
                    helperText={touched.client_email && errors.client_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
               </>)
                :
                <>
                 <Grid item xs={6} sm={6} >
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="authorized_person"
                    label={"POC Name (Authorized Person)"}
                    name="authorized_person"
                    value={values.authorized_person}
                    error={Boolean(touched.authorized_person && errors.authorized_person)}
                    helperText={touched.authorized_person && errors.authorized_person}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="business_nature"
                  label={"Business Nature"}
                  name="business_nature"
                  value={values.business_nature}
                  error={Boolean(touched.business_nature && errors.business_nature)}
                  helperText={touched.business_nature && errors.business_nature}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <ErrorFocus />
              </Grid>
                </>
                }
               
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="client_address"
                    label="Client Address"
                    name="client_address"
                    multiline
                    minRows={2}
                    value={values.client_address}
                    error={Boolean(touched.client_address && errors.client_address)}
                    helperText={touched.client_address && errors.client_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                  </>
                  : null
                }
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", gap: 2, justifyContent: "end" }}
                >
                  <Button
                    color="primary"
                    className={classes.popDownBtn}
                    size="medium"
                    type={values.downloadType ==='2' ? "": "submit"}
                    name="submitType"
                    value="report"
                    variant="contained"
                    onClick={()=>{
                      values.downloadType ==='2' && props.formSubmit(values) 
                    }}
                  >
                    {loading === false ? (
                      <> {"Generate Document"}</>
                    ) : (
                      <>
                        <img
                          alt="loader"
                          src={loadingSpinner}
                          className={classes.buttonProgress}
                        />{" "}
                        Generate Document{" "}
                      </>
                    )}
                  </Button>
                  {props.data ? (
                  <Button
                    variant="contained"
                    onClick={() => setShow(true)}
                    className={classes.popDownBtn}
                  >
                    Preview
                  </Button>
                ) : null}
                {props.data ? (
                  <Button
                    variant="contained"
                    onClick={() => downloadFile(props.data,values)}
                    className={classes.popDownBtn}
                  >
                    Download
                  </Button>
                ) : null}
                </Grid>
              </Grid>
            </Container>
          </form>
        )}
      </Formik>
      <ModalCentered
        title={"Preview"}
        fullScreen={true}
        open={show}
        toggle={() => setShow(false)}
        children={
          <div style={{ textAlign: "center", position: "relative" }}>
            <Grid container style={{ display: "flex" }}>
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <iframe
                    title="iframe"
                    style={{ width: "100%", height: "calc(100vh - 100px)" }}
                    src={props.data}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        }
      />
    </StyledContainer>
  );
};

export default EmpanelmentDocument;
