import React, { useEffect , useState} from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
  Checkbox,
  Container,
  TextField,
  FormControlLabel,
  FormControl,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import loadingSpinner from "src/images/loading-spinner.gif";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as Yup from "yup";
import { Formik } from "formik";
import { getLookUpDataService } from "src/_services/lookUp";
import ErrorFocus from "src/components/ErrorFocus/ErrorFocus";

const PREFIX = 'TagCreateForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  wrapper: `${PREFIX}-wrapper`,
  btnroot: `${PREFIX}-btnroot`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: "relative",
  },

  [`& .${classes.btnroot}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function TagCreateForm(props) {

  const [loading, setLoading] = React.useState(false);
  const [initValues, setInitValues]=useState({
    tagName: "",
    fileType: "",
    file: "",
    url: "",
    status: true,
    scrips: []
  })
  const timer = React.useRef();
  useEffect(()=>{
    if(props.mode==="edit"){
      setInitValues(props.data)
    }
  },[])

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/svg+xml",
    
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  const [allScrips, setAllScrips] = useState([])
  useEffect(() => {
    props.name ==="Group" &&
      getLookUpDataService.getLookSecurity()
          .then(res => {
              if (res) {
                  if (res.status === 200) {
                      let item = res.data.sort((a, b) => a.name.localeCompare(b.name)).map(data => {
                          return { id: data.id, label: data.name }
                      })
                      const filteredArray =props.data?.scrips?.length>0 ? item.filter(item => props.data?.scrips.map(Number).includes(item.id)) :[];
                      setAllScrips(item)
                      let data =props.mode === "create"? initValues : props.data
                      setInitValues({
                        ...data,
                        scrips:filteredArray
                      })
                  } else {
                      if (res.status === 401 && res.data.name === "TokenExpiredError") {
                          // setOpenSessionExpiredModal(true)
                      }
                  }
              }
          }).catch((error) => {
              console.log(error)
          })
  }, [])

  return (
    <StyledContainer>
      <Formik
       enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          tagName: Yup.string().required("Required"),
          file: Yup.mixed().when("fileType", {
            is: "file",
            then: Yup.mixed()
              .test(
                "fileFormat",
                "Unsupported Format ",
                (value) => value && SUPPORTED_FORMATS.includes(value.type)
              )
              .test(
                "fileSize",
                "Maximum file size is 10MB",
                (value) => value && value.size < 10242880
              ),
            otherwise: Yup.mixed(),
          }),
        })}
        onSubmit={(values) => {
          setLoading(true);
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label={`${props.name} Name`}
                id="tagName"
                name="tagName"
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
                fullWidth
                value={values.tagName}
                error={Boolean(touched.tagName && errors.tagName)}
                helperText={touched.tagName && errors.tagName}
                aria-describedby="outlined-weight-helper-text"
              />
              {
                props.name ==="Group" ?
                <>
                            <FormControl variant="outlined" fullWidth={true} >
                            <Autocomplete
                                    multiple
                                    value={values.scrips}
                                    aria-describedby="outlined-weight-helper-text"
                                    onBlur={handleBlur}
                                    onChange={(e, value) => {
                                        if (value !== null) {
                                            setFieldValue("scrips", value);
                                        }
                                    }}
                                    ListboxProps={{
                                      style: {
                                        maxHeight: '300px', // Ensures dropdown is scrollable
                                        overflow: 'auto',
                                      },
                                    }}
                                    filterSelectedOptions
                                    name="scrips"
                                    id="checkboxes-tags-demo"
                                    options={allScrips}
                                    inputProps={{
                                        shrink: true,
                                    }}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props,option, { selected }) => (
                                   
                                        <li {...props} >

                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            variant="outlined" label="Scrips" />
                                    )}
                                />
                                <FormHelperText style={{ color: "#F44336", position: "absolute", top: "53px" }}>
                                    {errors.scrips &&
                                        touched.scrips &&
                                        errors.scrips}
                                </FormHelperText>
                            </FormControl>
                            <ErrorFocus />
                            <Button onClick={() => {
                                const allValues = allScrips.map((option) => option);
                                setFieldValue('scrips', allValues)
                            }}>Select All</Button>
                            <br/>
                </>:
                null
              }
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.status}
                    name="status"
                    id="status"
                    aria-describedby="outlined-weight-helper-text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Active"
              />

              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false && props.mode === "create" ? 
                  "Add" :  loading === false && props.mode === "edit" ? "Update" 
                : (
                  <>
                    <img
                      src={loadingSpinner}
                      alt="Logo"
                      className={classes.buttonProgress}
                    />{" "}
                    {props.mode === "create" ? "Add" : "Update"}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default TagCreateForm;
