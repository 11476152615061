import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import UpdateTicket from "./UpdateTicket";
import NormalCard from "src/components/Cards/NormalCard";
import { Stack, Tooltip, Typography } from "@mui/material";
import DashboardLogs from "../ServiceOrders/ServiceOrderLogs";
import { userService } from "src/_services/users";
import SnackBar from "src/components/SnackBar/SnackBar";
import moment from "moment";

const TicketDetails = () => {
  let params = useParams();
  const location = useLocation();
  const data = location.state;
  const ticketId = params.id;
  const [comments, setComments] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  useEffect(() => {
    getTicketComments()
  }, []);

  const getTicketComments = () => {
    //   setLoading(true)
    userService.getTicketComments(params.id).then((res) => {
      if (res.status ===200) {
      setComments(res.data.data)
      }
    });
  };

  const submitHandle = (values) => {
    const data = {
      feedback: values.comments,
      status: parseInt(values.status),
      id: parseInt(values.id),
      question: values.subject,
      description: values.description,
      updated_values:values.updated_values,
      assign:values.assignee.id
    };
    userService
      .updateTicket(data)
      .then((res) => {
        if (res.data.success) {
          setSeverity("success");
          setSnackbarTitle("Updated Successfully");
          setSnackBarOpen(true);
          getTicketComments()
        } else {
          setSeverity("error");
          setSnackbarTitle("Failed to update");
          setSnackBarOpen(true);
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div style={{ margin: "1rem" }}>
      {location.state ? (
        <>
          <NormalCard title={"Update Ticket"}>
            <UpdateTicket
              //  spinner={btnLoading}
              formSubmit={submitHandle}
              type={""}
              mode={null}
              data={data}
            />
          </NormalCard>
          <NormalCard title={"Comments History"}>
            {
                comments.length>0 ?
                comments.map((item)=>{
                    return(
                        <Stack m={2} sx={{borderLeft:'2px solid grey',paddingLeft:'8px'}}>
                        <Typography sx={{fontSize:'12px',fontWeight:'bold'}}>  {`${item.name}`}  </Typography>
                        <Typography sx={{fontSize:'12px',color:'grey',fontWeight:'bold'}}> 
                          <Tooltip placement="right" title={`${moment(item.created_time).format('llll')}`}>
                          {` ${moment(item.created_time).fromNow()}`}
                          </Tooltip>
                        </Typography>
                        <Typography sx={{fontSize:'14px'}}>  {`${item.comments} `}</Typography>
                        </Stack>
                    )
                })
                :
                <>
                <Typography sx={{mt:2,textAlign:'center'}}>No Comments</Typography>
                </>
            }
          </NormalCard>
          <DashboardLogs id={ticketId} typeId={4} title="Ticket History" />
        </>
      ) : (
        <Typography>Invalid Operation</Typography>
      )}
      <SnackBar
        open={snackBarOpen}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
    </div>
  );
};

export default TicketDetails;
