import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Typography,
  Tooltip,
  AppBar,
  Box,
  Tabs,
  Tab,
} from "@mui/material";

import { ThemeProvider, useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import NormalCard from "src/components/Cards/NormalCard";
import IconButton from "@mui/material/IconButton";
import Page from "src/components/Page";
import { securityService } from "src/_services/security";
import SnackBar from "src/components/SnackBar/SnackBar";
import AlertModal from "src/components/AlertModal/AlertModal";
import moment from "moment";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import ServiceCard from "src/components/Cards/ServiceCard";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import PropTypes from "prop-types";
import InventoryAndPriceItems from "../../containers/stocks/InventaryAndPrice";
import SecuritySortIndexSubTab from "../../containers/Security/securitySortIndexSubTab";
import CopytoClipboard from "src/components/ButtonComponents/CopytoClipboard";
import { useSelector } from "react-redux";
import GetMuiTheme from "src/components/theme";
import InternalInventoryandPrice from "../stocks/InternalInventoryandPrice";
const PREFIX = "SecurityData";

const classes = {
  tooltip: `${PREFIX}-tooltip`,
  tableEllips: `${PREFIX}-tableEllips`,
  card: `${PREFIX}-card`,
  bottomborder: `${PREFIX}-bottomborder`,
  root: `${PREFIX}-root`,
  addButton: `${PREFIX}-addButton`,
  container: `${PREFIX}-container`,
  button: `${PREFIX}-button`,
  editdeletbtn: `${PREFIX}-editdeletbtn`,
  btnsdltedt: `${PREFIX}-btnsdltedt`,
  iconbtn: `${PREFIX}-iconbtn`,
  companyLogo: `${PREFIX}-companyLogo`,
  removeLink: `${PREFIX}-removeLink`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.tableEllips}`]: {
    display: "flex",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "11rem",
    [theme.breakpoints.down("sm")]: {
      width: "8rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "11rem",
    },
  },

  [`& .${classes.card}`]: {
    borderBottom: "none",
    padding: 0,
  },

  [`& .${classes.bottomborder}`]: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #e8e8e8",
  },

  [`& .${classes.root}`]: {
    margin: theme.spacing(2),
    "& .MuiTableHead-root": {
      background: "blue",
    },
  },

  [`& .${classes.addButton}`]: {
    textAlign: "end",
    marginLeft: "10px",
  },

  [`& .${classes.container}`]: {
    // minHeight: '100%',
    padding: "24px",
  },

  [`& .${classes.button}`]: {
    marginBottom: "1%",
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },

  [`& .${classes.editdeletbtn}`]: {
    color: "#3363cb",
    display: "flex",
  },

  [`& .${classes.btnsdltedt}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.iconbtn}`]: {
    padding: theme.spacing(0.8),
  },

  [`& .${classes.companyLogo}`]: {
    "&:hover": {
      transform: "scale(3.5)",
      filter: "alpha(opacity=30)",
    },
  },

  [`& .${classes.removeLink}`]: {
    cursor: "pointer",
  },
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} />; // eslint-disable-line
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {/* <Typography></Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SecurityData = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [securityData, setSecurityData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [severity, setSeverity] = React.useState("");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [rowindex, setRowindex] = useState();
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const [activeTab, setActiveTab] = React.useState(
    location?.state ? location?.state : 0
  );
  const [priceTrendData, setPriceTrendData] = React.useState();
  const userRole = useSelector(
    (state) => state?.authReducer?.auth?.user.default_role_code
  );
  // const [page, setPage] = React.useState(0);
  //   const [btnSwitch, setbtnSwitch] = React.useState(true);

  const getSecurity = () => {
    setLoading(true);
    securityService
      .getSecurity()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setSecurityData(res.data.mainArray);
            setLoading(false);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
              setLoading(false);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const getSecurityPriceTrend = () => {
    securityService
      .getSecurityPriceTrend()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setPriceTrendData(res.data.data);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
              setLoading(false);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getSecurity();
    getSecurityPriceTrend();
  }, []);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    // setPage(0);
    // setbtnSwitch(!btnSwitch);
  };

  const handleChangeIndex = (index) => {
    setActiveTab(index);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getMuiTheme = GetMuiTheme


  const [hover, setHover] = useState(false);
  const onHover = (value) => {
    setHover(true);
    navigator.clipboard.writeText(value);
  };

  const onLeave = () => {
    setHover(false);
  };

  const CopyText = (value) => {
    return (
      <div
        onClick={() => {
          onHover(value);
        }}
        onMouseLeave={onLeave}
        style={{ display: "flex", cursor: "copy" }}
      >
        <Tooltip title={hover ? "Copied" : "Click to Copy"}>
          <Typography
            variant="body2"
            style={{ textTransform: "capitalize" }}
            noWrap
          >
            {value}
          </Typography>
        </Tooltip>
      </div>
    );
  };
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "name",
      label: "Scrip Name",
      options: {
        hint: "Name of the Scrips",
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const name =
            value && value.length > 35 ? value.substr(0, 35) + "..." : value;
          return (
            <div
              style={{ display: "flex", alignItems: "center", width: "16rem" }}
              onMouseEnter={() => {
                setHover(true);
                setRowindex(tableMeta.rowData[0]);
              }}
              onMouseLeave={() => {
                setHover(false);
                setRowindex();
              }}
            >
              <Typography
                variant="body2"
                style={{ textTransform: "capitalize", cursor: "copy" }}
                component={LinkBtn}
                to={`/app/security-details/${tableMeta.rowData[0]}`}
                noWrap
                color="primary"
              >
                {name}
              </Typography>
              {rowindex === tableMeta.rowData[0] && (
                <Tooltip
                  style={{ cursor: "copy" }}
                  title={hover ? "Copied" : "Click to Copy"}
                >
                  <CopytoClipboard userBasicInfo={value} state={hover} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
   
    {
      name: "isin",
      label: "ISIN",
      options: {
        hint:
          "International Scrips Identification  Number - Unique code for Scrips ",
        filter: false,
        sort: false,
        customBodyRender: (value) => CopyText(value),
      },
    },
    {
        name: "face_value",
        label: "Face Value",
        options: {
            hint: 'Nominal Value of Securities Stated by Issuer',
            filter: false,
            sort: false,
            customBodyRender: (value) => CopyText(value) ,
        }
    },
    // {
    //     name: "related_security_tag",
    //     label: "Tag",
    //     options: {
    //         hint: 'Scrip',
    //         filter: true,
    //         sort: true,
    //         customBodyRender: (value) => {
    //             const resValueRole = value?.slice(0,2).map((value, i) => {
    //                 return (
    //                   <Chip
    //                     size="small"
    //                     style={{marginRight:'4px'}}
    //                     avatar={<Avatar alt={value.tag_name} src={value.tag_icon_url}/>}
    //                     className={classes.chipMargin}
    //                     label={value.tag_name}
    //                     variant="outlined"
    //                   />
    //                 );
    //               });
    //               return (
    //               <>
    //                 {resValueRole}
    //                {value?.length > 2 &&
    //                 <HtmlTooltip
    //                 title={
    //                   <React.Fragment>
    //                    {value.map((value)=>{
    //                     return(
    //                         <Chip
    //                         size="small"
    //                         style={{marginRight:'4px'}}
    //                         avatar={<Avatar alt={value.tag_name} src={value.tag_icon_url}/>}
    //                         className={classes.chipMargin}
    //                         label={value.tag_name}
    //                         variant="outlined"
    //                       />
    //                     )
    //                    })}
    //                   </React.Fragment>
    //                 }
    //                 placement="right"
    //                 TransitionComponent={Zoom}
    //               >
    //                <Chip
    //                style={{cursor:"pointer"}}
    //                size="small"
    //                color="primary"
    //             //    avatar={<Avatar alt={value.tag_name} src={value.tag_icon_url}/>}
    //                className={classes.chipMargin}
    //                label={"more..."}
    //              />
    //              </HtmlTooltip>}
    //               </>
    //               );
    //     }}
    // },
    {
      name: "last_updated",
      label: "Last Updated",
      options: {
        hint: "Update to Security Details",
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          let valueMoment = moment(value).format("DD-MM-YYYY LTS");
          return (
            <div className={classes.tableEllips}>
              <Typography variant="body2" noWrap>
                {valueMoment}
              </Typography>
            </div>
          );
        },
      },
    },
    // {
    //     name: "sort_index",
    //     label: "Sort Index",
    //     options: {
    //         filter: true,
    //         sort: false,
    //         display: true
    //     }
    // },
    {
      name: "",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span className={classes.editdeletbtn}>
              <IconButton
                onClick={() => handleEditTableRow(tableMeta.rowData[0])}
                className={classes.iconbtn}
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  setDeleteOpen(true);
                  setDeleteId(tableMeta.rowData[0]);
                }}
                className={classes.iconbtn}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </span>
          );
        },
      },
    },
  ];

  const PriceTrendColumns = [
    {
      name: "related_security_id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "name",
      label: "Scrip Name",
      options: {
        hint: "Name of the Scrips",
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
              variant="body2"
              style={{ textTransform: "capitalize" }}
              component={LinkBtn}
              noWrap
              to={`price-trend/${tableMeta.rowData[0]}`}
              color="primary"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "price",
      label: "Price",
    },
    {
      name: "",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span className={classes.editdeletbtn}>
              <IconButton
                component={LinkBtn}
                target={"_blank"}
                to={`price-trend/${tableMeta.rowData[0]}`}
                className={classes.iconbtn}
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
            </span>
          );
        },
      },
    },
  ];

  const handleEditTableRow = (id) => {
    navigate("/app/security-create?mode=edit", {
      replace: true,
      state: { userID: id },
    });
  };


  const priceTrendcolumn =
    userRole === "OPERATION" || userRole === "ADMIN"
      ? PriceTrendColumns
      : PriceTrendColumns.slice(0, 3);
  const handleDeleteTableRow = () => {
    setSpinner(true);
    securityService
      .deleteSecurityByID(deleteId)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setSpinner(false);
            getSecurity();
            setDeleteOpen(false);
            setSeverity("success");
            setSnackbarTitle("Security deleted");
            setOpen(true);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
              setDeleteOpen(false);
              setSpinner(false);
            } else {
              setSpinner(false);
              setDeleteOpen(false);
              setSeverity("error");
              setSnackbarTitle("Failed to delete");
              setOpen(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setDeleteOpen(false);
        setSpinner(false);
        setSeverity("error");
        setSnackbarTitle("Something Went Wrong !");
        setOpen(true);
      });
  };

  const goToCreateSecurityPage = () => {
    navigate("/app/security-create?mode=create", { replace: true });
  };
  const goToNews = () => {
    navigate("/app/security-news");
  };
  
  const dataColumns =(userRole==="ADMIN" || userRole==="OPERATION") ? columns: columns.slice(0, columns.length - 1)

  return (
    <Root>
      <Page title="Unlistedkart | Security List">
        <ServiceCard className={classes.card}>
          <ThemeProvider theme={getMuiTheme}>
            <div style={{ position: "relative" }}>
              <div className={classes.bottomborder}>
                <AppBar position="static">
                  <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    style={{
                      // marginBottom: 24,
                      borderBottom: "1px solid #e8e8e8",
                    }}
                  >
                    <Tab label="Scrips" {...a11yProps(0)} />
                    <Tab label="Inventory & price" {...a11yProps(1)} />
                    <Tab label="Sort Index" {...a11yProps(2)} />
                    <Tab label="Price Trend" {...a11yProps(3)} />
                    <Tab label="Internal Groups" {...a11yProps(4)} />
                  </Tabs>
                </AppBar>
              </div>
            </div>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeTab}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={activeTab} index={0}>
                <NormalCard
                  title={"Scrips"}
                  subtitle={"List of Scrips and their details"}
                  btn={ userRole !== "ADMIN_VIEWER" ?
                    <div style={{ display: "flex" }}>
                      <Button
                        className={classes.addButton}
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={goToCreateSecurityPage}
                      >
                        Create New Scrip
                      </Button>
                      <Button
                        className={classes.addButton}
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={goToNews}
                      >
                        News
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => {
                          navigate("/app/security-tags");
                        }}
                        size="medium"
                        type="submit"
                        variant="contained"
                        className={classes.addButton}
                        name="addDemat"
                        value="addDemat"
                      >
                        {/* <GetAppIcon /> */}
                        Scrip Tags
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => {
                          navigate("/app/security-groups");
                        }}
                        size="medium"
                        type="submit"
                        variant="contained"
                        className={classes.addButton}
                        name="addDemat"
                        value="addDemat"
                      >
                        {/* <GetAppIcon /> */}
                        Scrip Groups
                      </Button>
                    </div> : null
                  }
                >
                  <ThemeProvider theme={getMuiTheme}>
                    <MUIDataTable
                      data={securityData}
                      headerSort={false}
                      columns={dataColumns}
                      options={{
                        
                        // tableBodyMaxHeight:800,
                        rowsPerPage: 10,
                        rowsPerPageOptions: [5, 10, 25],
                        // responsive: 'scrollMaxHeight',
                        selectableRows: "none",
                        // sortOrder: {
                        //     name: 'last_updated',
                        //     direction: 'desc'
                        // },
                        download: true,
                        viewColumns: false,
                        print: false,
                        textLabels: {
                          body: {
                            noMatch: loading
                              ? "Loading..."
                              : "Sorry, there is no matching data to display",
                          },
                        },
                      }}
                    />
                  </ThemeProvider>
                </NormalCard>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <InventoryAndPriceItems userRole={userRole} />
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <SecuritySortIndexSubTab />
              </TabPanel>
              <TabPanel value={activeTab} index={3}>
                <NormalCard
                  title={"Scrips Price Trend"}
                  subtitle={"List of Scrips and their details"}
                >
                  <ThemeProvider theme={getMuiTheme}>
                    <MUIDataTable
                      data={priceTrendData}
                      headerSort={false}
                      columns={priceTrendcolumn}
                      options={{
                        rowsPerPage: 10,
                        rowsPerPageOptions: [5, 10, 25],
                        // responsive: 'scrollMaxHeight',
                        selectableRows: "none",
                        // sortOrder: {
                        //     name: 'last_updated',
                        //     direction: 'desc'
                        // },
                        download: true,
                        viewColumns: false,
                        print: false,
                        textLabels: {
                          body: {
                            noMatch: loading
                              ? "Loading..."
                              : "Sorry, there is no matching data to display",
                          },
                        },
                      }}
                    />
                  </ThemeProvider>
                </NormalCard>
              </TabPanel>
              <TabPanel value={activeTab} index={4}>
                <InternalInventoryandPrice userRole={userRole} />
              </TabPanel>
            </SwipeableViews>
          </ThemeProvider>
        </ServiceCard>
      </Page>
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />

      <AlertModal
        title="Are you sure you want to delete this?"
        open={deleteOpen}
        setOpen={setDeleteOpen}
        toggle={() => setDeleteOpen(false)}
        onConfirm={() => handleDeleteTableRow()}
        openLoading={spinner}
      ></AlertModal>

      <SessionExpiredModal open={openSessionExpiredModal} />
    </Root>
  );
};
export default SecurityData;
