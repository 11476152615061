import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Grid,
  Typography,
  Container,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import loadingSpinner from "src/images/loading-spinner.gif";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { getLookUpDataService } from "src/_services/lookUp";
import compareJSON from "src/components/comparision";
import ModalCentered from "src/components/Modals/ModalCentered";
import ImageRotate from "../Profile/Rotate";

const PREFIX = "AdvanceSearchForm";

const classes = {
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "5%",
  },

  [`& .${classes.label}`]: {
    marginTop: "10px",
    fontWeight: "bold",
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",

    marginTop: theme.spacing(1),
    // marginBottom: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));
const UpdateTicket = ({ data, formSubmit }) => {
  const state = useSelector((state) => state.authReducer.auth.user);
  let userId = state.id;
  let role = state.default_role_code;
  const [show, setShow] = useState(false);
  const [assignee, setAssignee] = React.useState([]);
  const [url, setUrl] = useState({
    url: "",
    isPdf: false,
  });
  const [loading, setLoading] = useState(false);
  let originValue = {
    id: data[0],
    status: Number(data[5]) ===1 ? "Open" : Number(data[5]) ===2 ? "In Progress" : "Closed",
    description: data[4],
    subject: data[3],
    comments: '',
    fileId: data[7],
    url: null,
    assignee:{
      id:data[15],
      label:data[11]
    }
  };
  let initialValues = {
    id: data[0],
    status: data[5],
    description: data[4],
    subject: data[3],
    comments: '',
    fileId: data[7],
    url: null,
    assignee:{
      id:data[15],
      label:data[11]
    }
  };

  const handleFileUrl = () => {
    //   setLoading(true)
    setShow(true);
    setUrl({
      url: null,
      isPdf: null,
    });
    getLookUpDataService.getObjectUrl(data[7]).then((res) => {
      if (res.data.success) {
          setLoading(false)
          setUrl({
            url: res.data.data,
            isPdf:
              res.data.mimetype === "application/pdf" ||
              res.data.mimetype === ".pdf"
                ? true
                : false,
          });
        originValue ={...originValue,url:res.data.data}
      }
    });
  };



  useEffect(() => {
    getLookUpDataService.getTicketAssignUserList('OPERATION,ADMIN').then((res)=>{
      let data =res.data.map((data) => {
        return { id: data.id, label: data.user_name };
      })
      setAssignee(data)
    })
  }, []);

  const renderDetails = (label, value) => {
    return (
      <>
        <Grid item xs={2} mt={1}>
          <Typography sx={{ fontWeight: "bold" }}>{label} :</Typography>
        </Grid>
        <Grid item xs={10} mt={1}>
          <Typography sx={{ marginLeft: "8px" }}>{value}</Typography>
        </Grid>
      </>
    );
  };
  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          subject: Yup.string().required("Required"),
          description: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          //   setLoading(true);
          const newValue ={...values,status:Number(values.status)===1 ? "Open" : Number(values.status) ===2 ? "In Progress" : "Closed"}
          const result = compareJSON(newValue,originValue);
          formSubmit({...values,status:values.status,updated_values:JSON.stringify(result)});
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          setFieldValue,
          handleBlur,
          errors,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              {data.length > 0 ? (
                <>
                  {/* <Typography variant="h4">
                                        Ticket Details
                                    </Typography> */}
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                      <Grid container sx={{ fontWeight: "500", mt: 2 }}>
                        {userId === data[1] ? (
                          <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                             <Autocomplete
                              id="internal-representative"
                              // options={representatives}
                              className={classes.inputMargin}

                              options={assignee}
                              getOptionLabel={(option) => option.label || ""}
                              value={values.assignee}
                              onChange={(e, value) => {
                                setFieldValue("assignee", value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.assignee &&
                                      errors.assignee
                                  )}
                                  helperText={
                                    touched.assignee &&
                                    errors.assignee
                                  }
                                  name="internalrepresentative"
                                  label="Assign"
                                  fullWidth
                                  variant="outlined"
                                />
                              )}
                            />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                className={classes.inputMargin}
                                variant="outlined"
                                label="Subject*"
                                id="subject"
                                name="subject"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                autoComplete="off"
                                fullWidth
                                value={values.subject}
                                error={Boolean(
                                  touched.subject && errors.subject
                                )}
                                helperText={touched.subject && errors.subject}
                                aria-describedby="outlined-weight-helper-text"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                className={classes.inputMargin}
                                variant="outlined"
                                label="Description*"
                                id="description"
                                name="description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                autoComplete="off"
                                fullWidth
                                multiline
                                minRows={4}
                                value={values.description}
                                error={Boolean(
                                  touched.description && errors.description
                                )}
                                helperText={
                                  touched.description && errors.description
                                }
                                aria-describedby="outlined-weight-helper-text"
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            {renderDetails("Assignee", values.assignee?.label)}
                            {renderDetails("Subject", values.subject)}
                            {renderDetails("Message", values.description)}
                          </>
                        )}
                        {data && data[7] ? (
                          <>
                            <Grid item xs={12} display={'flex'}  sx={{ fontWeight: "bold", paddingTop: "16px"}}>
                              <Typography sx={{ fontWeight: "bold" }}>
                                File :
                              </Typography>
                            
                              <Typography
                              ml={1}
                              sx={{textDecoration:'underline',cursor:'pointer'}}
                              component={"span"}
                              onClick={handleFileUrl}
                              > Preview</Typography>
                            </Grid>
                          </>
                        ) : null}

                      {
                         role === "ADMIN" &&<>
                      <Grid item xs={12} mt={2}>
                          <TextField
                            placeholder="Comments"
                            name="comments"
                            fullWidth
                            variant="outlined"
                            label="Comments*"
                            value={values.comments}
                            onChange={handleChange}
                            multiline
                            rows={2}
                          />
                        </Grid>
                        <Grid item xs={12} pt={2}>
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Status*
                            </InputLabel>
                            <Select
                              id="status"
                              fullWidth
                              style={{ marginBottom: 8 }}
                              variant="outlined"
                              error={Boolean(touched.status && errors.status)}
                              name="status"
                              label="Status*"
                              value={values.status}
                              aria-describedby="outlined-weight-helper-text"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="off"
                            >
                              <MenuItem key="saving" value="1">
                                Open
                              </MenuItem>
                              
                                <MenuItem value="2">In Progress</MenuItem>
                              
                              <MenuItem key="current" value="3">
                                Closed
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                         </>}
                      
                      </Grid>
                    </Grid>
                  </Grid>
                  <Button
                    color="primary"
                    className={classes.popDownBtn}
                    size="medium"
                    disabled={loading || (userId !==data[15] && userId !==data[1])}
                    type="submit"
                    variant="contained"
                  >
                    {loading === false ? (
                      "Update"
                    ) : (
                      <>
                        <img
                          src={loadingSpinner}
                          alt="Logo"
                          className={classes.buttonProgress}
                        />{" "}
                        Update{" "}
                      </>
                    )}
                  </Button>
                </>
              ) : (
                <Typography sx={{ textAlign: "center", my: 3 }}>
                  No accounts found
                </Typography>
              )}
            </Container>
          </form>
        )}
      </Formik>
      <ModalCentered
          title={url ? "Preview" :"Loading..."}
          fullScreen={true}
          open={show}
          toggle={() => {
            setShow(false);
            setUrl(false);
          }}
          children={
            url.url ? (
              <div style={{ textAlign: "center", position: "relative" }}>
                <Grid
                  container
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item xs={12} md={10}>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {url.isPdf ? (
                        <iframe
                        title="frame"
                          style={{
                            width: "100%",
                            height: "calc(100vh - 100px)",
                          }}
                          src={url.url}
                        />
                      ) : (
                        <ImageRotate
                          alt="cool image"
                          type="true"
                          image={url && url.url}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
                <br />
              </div>
            ) : (
              <div>Loading...</div>
            )
          }
        />
    </StyledContainer>
  );
};

export default UpdateTicket;
