import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  Container,
  TextField,
  Grid,
  Typography,
  Chip,
  OutlinedInput,
  FormHelperText,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import loadingSpinner from "src/images/loading-spinner.gif";
import ModalCentered from "src/components/Modals/ModalCentered";
import ImageRotate from "../Profile/Rotate";
import { serviceOrderService } from "src/_services/serviceOrder";
import SnackBar from "src/components/SnackBar/SnackBar";

const PREFIX = "OrderExportForm";

const classes = {
  label: `${PREFIX}-label`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  [`& .${classes.popDownBtn}`]: {
    // borderRadius: "0 0 12px 12px",
    padding: "8px",
    textTransform: "none",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },
  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

const UploadCounterSignedDealTerm = (props) => {
  const [tags, setTags] = useState([]);
  const [preview, setPreview] = useState();
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleAddTag = (e, setFieldValue) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      e.preventDefault();
      if (e.target.value.includes(" ")) {
        let orderArray = e.target.value
          .split(" ")
          .map((item) => item.trim().replace(",", ""));
        setTags([...tags, ...orderArray]);
      } else {
        setTags([...tags, e.target.value]);
      }
      e.target.value = "";
    }
    setFieldValue("tagName", "");
  };

  const handleDelete = (i) => {
    setTags((tag) => tag.filter((name, index) => index !== i));
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  const generateDealTerm =(async(values)=>{
    let orderId = [];
    let filteredArr=[]
    setSpinner(true)
    if(values.document_id){
      let orders = await serviceOrderService.getDealtermOrders(values.document_id)
      if(orders.status ===200){
        filteredArr =orders.data
      }else{
        setSpinner(false)
        setOpen(true);
        setSnackbarTitle(orders.data.message);
        setSeverity("error");
      }
    }else{
      if (values.tagName?.includes(" ")) {
        let orderArray = values.tagName
          .split(" ")
          .map((item) => item?.trim().replace(",", ""));
        orderId = [...tags, ...orderArray];
      } else {
        orderId = [...tags, values.tagName];
      }
      const orders = orderId
        .map((order) => order.replace(/\D/g, ""))
        .map((order) => Number(order));
       filteredArr = orders.filter((item) => item !== "" && item !== 0);
    }
    const data={
        order_id:filteredArr,
        doc:values.doc
    }
    if(data.order_id.length>0){
      setSpinner(true)
      props.formSubmit(data)
    }
  })

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={{doc:'',tagName:"",document_id:''}}
        validationSchema={Yup.object().shape({
          doc: Yup.mixed()
          .required("A file is required")
          .test(
            "fileFormat",
            "Unsupported Format",
            (value) => value && SUPPORTED_FORMATS.includes(value.type)
          )
          .test(
            "fileSize",
            "Maximum file size is 10MB",
            (value) => value && value.size < 10242880
          ),
        })}
        onSubmit={(values) => {
          generateDealTerm(values);
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>              
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  helperText=""
                  label={`Enter by order id's`}
                  onKeyPress={(e) => handleAddTag(e, setFieldValue)}
                  name="tagName"
                  // disabled={orders}
                  onChange={handleChange}
                />
                <div >
                  {tags &&
                    tags.map((item, index) => (
                      <Chip
                        // disabled={orders}
                        style={{ margin: "4px" }}
                        key={index}
                        tabIndex={-1}
                        label={item}
                        onDelete={() => handleDelete(index)}
                      />
                    ))}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} textAlign={'center'} >
                    <Typography>
                      OR
                    </Typography>
              </Grid>
              <Grid item xs={12} sm={12} >
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="document_id"
                    label="Document Id"
                    type="number"
                    name="document_id"
                    error={Boolean(touched.document_id && errors.document_id)}
                    helperText={touched.document_id && errors.document_id}
                    onChange={handleChange}
                    value={values.document_id}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              <Grid item xs={12} p={0}>
                      <Typography className={classes.label}>
                        Dealterm document
                      </Typography>
                      <OutlinedInput
                        accept="image/*"
                        disabled ={values.tagName ===""&& tags.length ===0 && !values.document_id}
                        // className={classes.input}
                        id="icon-button-file"
                        name="doc"
                        fullWidth={true}
                        onChange={(e) => {
                          setFieldValue("doc", e.target.files[0]);
                        }}
                        type="file"
                      />

                      {values.doc && !errors.doc ? (
                        <FormHelperText
                          onClick={() => {
                            const url = URL.createObjectURL(values.doc);
                            setPreview({
                              url: url,
                              type:
                                values.doc.type === "application/pdf"
                                  ? true
                                  : false,
                            });
                          }}
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          {"Preview"}
                        </FormHelperText>
                      ) : (
                        <FormHelperText
                          style={{ color: "#F44336", paddingLeft: 16 }}
                        >
                          {errors.doc}
                        </FormHelperText>
                      )}
                    </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                gap={2}
                display={"flex"}
                justifyContent="end"
              >
                <Button
                  color="primary"
                  className={classes.popDownBtn}
                  size="medium"
                  type="submit"
                  disabled={spinner || (tags.length ===0 && values.tagName ==="" && !values.document_id) }
                  // onClick={()  => generateDealTerm(values)}
                  variant="contained"
                >
                  {spinner === false ? (
                    <> {"Upload"}</>
                  ) : (
                    <>
                      <img
                        alt="loader"
                        src={loadingSpinner}
                        className={classes.buttonProgress}
                      />{" "}
                      Upload{" "}
                    </>
                  )}
                </Button>
               
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ModalCentered
        title={"Preview"}
        open={preview}
        toggle={() => setPreview(false)}
        // maxWidth="md"
        fullScreen="true"
        children={
          <Box sx={{ textAlign: "center" }}>
            {preview?.type ? (
              <iframe
                title="iframe"
                style={{ width: "100%", height: "calc(100vh - 100px)" }}
                src={preview.url}
              />
            ) : (
              <ImageRotate
                alt="cool image"
                type="users"
                image={preview && preview.url}
              />
            )}
          </Box>
        }
      />
        <SnackBar
            open={open}
            severity={severity}
            close={handleClose}
            snackbarTitle={snackbarTitle}
          />
    </StyledContainer>
  );
};

export default UploadCounterSignedDealTerm;
