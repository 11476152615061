import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

//temp components
import AddSecondoryTrade from 'src/containers/AddSecondoryTrade/Index'
import DashboardView from 'src/views/reports/DashboardView';
import NotFoundView from 'src/views/errors/NotFoundView';
import GroupSelectorCreateForm from "src/containers/GroupSelector/GroupSelector"
import IrmInventoryAndPrice from "src/containers/stocks/IrmInventoryAndPrice"
//end temp components
import ServiceOrderCreate from 'src/containers/ServiceOrders/ServiceOrderCreate';

import ChannelPartnerServiceOrderList from "src/containers/ChannelPartnerServiceOrder/Index"
import ChannelPartnerServiceOrderDetails from "src/containers/ChannelPartnerServiceOrder/ChannelPartnerServiceOrderDetails"

import InvestorClientServiceOrderList from "src/containers/InvestorClientServiceOrder/Index"
import Analytics from "src/containers/Analytics/Index"

import InvestorClientServiceOrderDetails from "src/containers/InvestorClientServiceOrder/InvestorClientServiceOrderDetails"
import InvestorClintProcurement from "src/containers/InvestorClientView/InvestorClintProcurement" 

//main components
import UsersData from './containers/Users/Index';
import UserDetails from './containers/Users/UserDetails';
import UserCreate from './containers/Users/CreateUsers';

import Securitis from './containers/Security/Index';
import LoginPage from 'src/containers/Login/Index'
import VerifyOtp from "src/containers/Login/VerifyOtp"
import SecurityDetails from './containers/Security/SecurityDetails';
import SecurityDataCreate from './containers/Security/SecurityCreate';
import ButtonLoading from 'src/components/ButtonLoading/ButtonLoading';
import Support from 'src/containers/Support/Support';

import ChannelPartners from "src/containers/ChannelPartners/Index"

import ContactInfo from "src/components/Tables/ContactInfo"
import ServiceOrderItems from './containers/ServiceOrders/ServiceOreder';
import ServiceOrderDetails from './containers/ServiceOrders/ServiceOrderDetails';
import AdditionalTradeList from './containers/AdditionalTrade/Index';
import Logs from 'src/containers/Logs/logs'
import Loading from 'src/components/Loading/Loading'
import ChannelPartnerIventory from './containers/ChannelPartnersView/ChannelPartnerInventory';
import InventoryClientDashboard from './containers/InvestorClientDashboard';
import ProfileDetails from './containers/Profile/Index';
import PolicyPage from './containers/Policy/Index';
import FAQsPage from './containers/FAQs/Index';
import FAQs from './containers/FAQs/FAQs';
import ServiceOrderItemsByUserID from './containers/ServiceOrders/ServiceOrderItemsByUserID';
import SecuritityTags from './containers/Security/SecuritityTags';
import SecurityNews from './containers/Security/SecurityNews';
import Allnews from './containers/Security/Allnews';
import PriceTrend from './containers/Security/PriceTrend';
import Partners from './containers/Partners';
import PartnerUsers from './containers/Partners/PartnerUsers';
import PartnerDetails from './containers/Partners/PartnerDetails';
import PartnerLogs from './containers/Partners/PartnerLogs';
import PartnerOrders from './containers/Partners/PartnerOrder';
import DeletedOrders from './containers/ServiceOrders/DeletedOrders';
import InvoiceFormPreview from './containers/ServiceOrders/InvoiceFormPreview';
import Version from './containers/Version/version';
import DealtermSignature from './containers/Dealterm';
import TicketDetails from './containers/Support/TicketDetails';
import SecurityGroups from './containers/Security/SecurityGroups';
const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [

      { path: 'dashboard', element: <DashboardView/> },
      { path: 'loading', element: <Loading /> },
      { path: 'investor-dashboard', element: <InventoryClientDashboard/>},
      { path: 'channel-partners-dashboard', element: <InventoryClientDashboard/>},
      { path: 'analytics', element: <Analytics /> },
      { path: 'service-order-create', element: <ServiceOrderCreate /> },
      { path: 'service-orders', element: <ServiceOrderItems/> },
      { path: 'client-service-orders/:id', element: <ServiceOrderItemsByUserID/> },
      { path: 'service-order-lists', element: <ServiceOrderItems/> },
      { path: 'service-order-details/:id', element: <ServiceOrderDetails/> },

      { path: 'security', element: <Securitis /> },
      {  path: 'security-tags', element: <SecuritityTags /> },
      {  path: 'security-groups', element: <SecurityGroups /> },
      { path: 'security-details/:id', element: <SecurityDetails/> },
      { path: 'security-create', element: <SecurityDataCreate/> },
      { path: 'security/news/:id', element: <SecurityNews/> },
      { path: 'security-news', element: <Allnews/> },
      { path: 'security/price-trend/:id', element: <PriceTrend/> },

      { path: 'channel-partners', element: <ChannelPartners/> },
      { path: 'partners', element: <Partners /> },
      { path: 'partner_orders', element: <PartnerOrders /> },
      { path: 'logs', element: <Logs /> },
      { path: 'partnerusers/:id', element: <PartnerUsers/> },
      { path: 'partnerlogs/:id', element: <PartnerLogs/> },
      { path: 'deleted-orders', element: <DeletedOrders/> },
      { path: 'channel-partners-views', element: <ChannelPartnerIventory/>},
      { path: 'invoice-form', element: <InvoiceFormPreview/>},

      { path: 'channel-partners-service-order-lists', element: <ChannelPartnerServiceOrderList/>},
      { path: 'channel-partners-service-order-details/:id', element: <ChannelPartnerServiceOrderDetails/> },
   
      { path: 'investor-client-service-order-lists', element: <InvestorClientServiceOrderList/>},
      { path: 'investor-client-service-order-details/:id', element: <InvestorClientServiceOrderDetails/> },
      
      { path: 'investor-client-procure-share', element: <InvestorClintProcurement/>},
      { path: 'user', element: <UsersData /> },
      { path: 'user-details/:id', element: <UserDetails/> },
      { path: 'partneruser-details/:id', element: <PartnerDetails/> },
      { path: 'user-create', element: <UserCreate/> },
      { path: 'user-create-table', element: <ContactInfo/> },

      { path: 'secondary-trade', element: <AddSecondoryTrade/> },

      { path: 'addition-trade', element: <AdditionalTradeList/> },
      { path: 'group-create', element: <GroupSelectorCreateForm /> },
      { path: 'irm/inventory-and-price', element: <IrmInventoryAndPrice /> },
      { path: 'tickets', element: <Support /> },
      { path: 'ticket/:id', element: <TicketDetails /> },
      { path: 'btn-loading', element: <ButtonLoading /> },

      { path: 'profile', element: <ProfileDetails/> },
      { path: '*', element: <Navigate to="/404" /> },

      { path: 'policy', element: <PolicyPage/> },
      { path: 'FAQs', element: <FAQsPage/> },
      { path: 'FAQs/:mode', element: <FAQs/> },
     ]
  },
  {
    path: '/',
    element: <MainLayout />,   
    children: [
      { path: 'login', element: <LoginPage /> },
      { path: 'verify-otp', element: <VerifyOtp /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      // { path: '*', element: <Navigate to="/404" /> },
      { path: '/system/version', element: <Version /> }
    ]
  },
  {
    path: '/dealtermsign/:id',
    element: <DealtermSignature />, 
  }
];

export default routes;
  
