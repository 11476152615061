import React, { useRef, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import SignatureCanvas from "react-signature-canvas";
import Dialog from "@mui/material/Dialog";
import {
  DialogContent,
  Typography,
  Button,
  Container,
  TextField,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
// import "./App.css";
import { serviceOrderService } from "src/_services/serviceOrder";
import moment from "moment";
import Logo from "./unlistedkart_logo.png";
import CryptoJS from "crypto-js";

const PREFIX = "App";

const classes = {
  buyBtn: `${PREFIX}-buyBtn`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.buyBtn}`]: {
    fontFamily: "Poppins  !important",
    fontWeight: "500 !important",
    fontSize: "12px !important",
    lineHeight: "24px !important",
    minWidth: "100px",
    color: "white",
    border: "none",
    marginLeft: "1rem",
    padding: "5px 1rem !important",
    borderRadius: "10px !important",
    background: "#6D50CC !important",
    textAlign: "center",
    marginBottom: "1rem !important",
    "&:disabled": {
      color: "grey",
    },
  },
}));

export default function SignatureDialog() {
  const url = window.location.href;

  const signRef = useRef(null);

  // let id = useParams();
  // console.log(id)
  // const useParamsId= id.id
  const [id, setId] = useState(null);
  const [imageSouce, setImageSouce] = useState(null);
  const [openSignaturePopUp, setOpenSignaturePopUp] = useState(false);
  const [otpverified, setOtpVerified] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [signed, setSigned] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [snackbarTitle, setSnackbarTitle] = useState();
  const [initialValue, setInitialValue] = useState();
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [signatureLength, setSignatureLength] = useState(0);
  const [file, setFile] = useState(null);
  const [cancelled, setCancelled] = useState(false);


  const [showDealtermSucess, setShowDealtermSucess] = useState(false);
  const [template, setTemplate] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  let email = urlParams.get("email");
  let name = urlParams.get("name");
  if (email && email.includes("?")) {
    const [actualEmail, extraQueryString] = email.split("?");
    email = actualEmail;
    const extraParams = new URLSearchParams("?" + extraQueryString);
    name = extraParams.get("name") || name; // Update name if it's in the extraParams
  }

  function readBlobAsText(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function(event) {
        resolve(event.target.result);
      };
      reader.onerror = function(error) {
        reject(error);
      };
      reader.readAsText(blob);
    });
  }

  const verifyOtp = () => {
    const data = {
      otp: otp,
      email: decryptedEmail,
      order_id:id.startsWith('MU') ?id.replace('MU','') : id,
    };
    serviceOrderService.verifyOtpforDealterm(data).then((res) => {
      if (res.status === 201) {
        setInvalidOtp(true);
      } else if (res.status === 200) {
        setOtpVerified(true);
      }
    });
  };

  const getDealTermDocumentTemplate = (id) => {
    try {
      serviceOrderService
        .getServiceOrderdDealTermTemplate(id)
        .then(async (res) => {
          if (res) {
            if (res.status === 200) {
              const file = await readBlobAsText(res.data);

              setTemplate(file);
              setInitialValue(file);
            } else {
              if (res.status === 401 ) {
                setCancelled(true)
              }
            }
          }
        });
    } catch (error) {
      setSnackbarTitle("Failed to load");
      setOpenSnackbar(false);
      setSeverity("error");
    }
  };

  function decryptData(encryptedText, key) {
    // Convert key to 128-bit (16 bytes) for AES decryption
    let keyBytes = CryptoJS.enc.Utf8.parse(key);

    // Decrypt using AES
    let decrypted = CryptoJS.AES.decrypt(encryptedText, keyBytes, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert decrypted data to UTF-8 string
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  let password = "ulksecretKey";
  let decryptedEmail = email && decryptData(email.replaceAll(" ", "+"), password);
  let decryptedName = name && decryptData(name.replaceAll(" ", "+"), password);
  function getCurrentDateTime() {
    const currentDateTime = new Date();

    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(currentDateTime.getDate()).padStart(2, "0");

    let hours = currentDateTime.getHours();
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");
    const seconds = String(currentDateTime.getSeconds()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${formattedHours}:${minutes}:${seconds} ${ampm}`;

    return {
      date: formattedDate,
      time: formattedTime,
    };
  }

  const currentDateTime = getCurrentDateTime();

  const getServiceOrderDocuments = async (id) => {
    try {
      await serviceOrderService.getServiceOrderDocuments(id).then((res) => {
        if (res.status === 200) {
          const findSignedData = res.data.some(
            (item) =>
              item.deal_term_document_type_code === "COUNTER_SIGNED_DEAL_TERM"
          );
          if (findSignedData) {
            setCompleted(true);
          }
        } else {
          setSnackbarTitle("Failed to load");
          setOpenSnackbar(false);
          setSeverity("error");
        }
      });
    } catch (error) {
      setSnackbarTitle("Failed to load");
      setOpenSnackbar(false);
      setSeverity("error");
    }
  };
  const resendOtp = async() => {
    setLoading(true);
    const data = {
      toMail: decryptedEmail,
      user_name:decryptedName,
      orderId:id.startsWith('MU') ?id.replace('MU','') : id,
    };
    let res = id.startsWith('MU') ? await serviceOrderService.sendEmailforMultipleOrderDealterm(data) : await serviceOrderService.sendEmailforDealterm(data) 
      if (res.status === 200) {
        setSnackbarTitle("Otp sent successfully");
        setOpenSnackbar(true);
        setSeverity("success");
        setLoading(false);
      } else {
        setSnackbarTitle("Otp failed to send");
        setOpenSnackbar(false);
        setSeverity("error");
      }
    
  };

  const getMultipleOrderDealterm =((id)=>{
    try {
      serviceOrderService
        .getServiceOrderdMultipleOrderDealTermTemplate(id)
        .then(async (res) => {
          if (res) {
            if (res.status === 200) {
              const file = await readBlobAsText(res.data);
              setTemplate(file);
              setInitialValue(file);
            } else if (res.status === 400 ) {
                setCompleted(true)
            }else if (res.status === 401 ) {
                setCancelled(true)
              }
          }
        });
    } catch (error) {
      setSnackbarTitle("Failed to load");
      setOpenSnackbar(false);
      setSeverity("error");
    }


  })

  useEffect(() => {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const id = pathname.split("/")[2];
    console.log(id);
    setId(id);
    if(id.startsWith('MU')){
      let payload_id =id.replace('MU','')
      getMultipleOrderDealterm(payload_id)
    }else{
      getDealTermDocumentTemplate(id);
      getServiceOrderDocuments(id);
    }
    // eslint-disable-next-line
  }, []);

  const applyDigitalSignature = () => {
    setTemplate(
      initialValue.replace(
        '<p style="margin-top:3rem;">(Authorized Signatory)</p>',
        `
      <p  style="font-family:Playwrite NZ;font-weight:500;font-size:1rem;margin:8px">${decryptedName}</p><span style="margin:0">(Digital Signature)<span><br/><p style="margin:0 16px 0">${currentDateTime.date} ${currentDateTime.time}</p></span>`
      )
    );
  };

  const generateDealTerm =async () => {
    setLoading(true);
    let blobData = template.replaceAll('src=', '\nsrc=')
    let generateDoc =  id?.startsWith('MU') ? await  serviceOrderService.generateBulkSignedDealTerm({ data: blobData }) :  await  serviceOrderService.generateSignedDealTerm({ data: blobData }) 
    if(generateDoc.status ===200){
        const file = new Blob([generateDoc.data], { type: "application/pdf" });
        setFile(file);
        if(id.startsWith('MU')){
          let payload_id =id.replace('MU','')
          let orders = await serviceOrderService.getDealtermOrders(payload_id)      
            
            const promises = orders.data.map((item) =>{
              const name = `UNLSORDER${item} Counter Signed Deal Term`;
              const formData = new FormData();
              const date = moment(new Date()).format("YYYY-MM-DD");
              formData.append("document_type", "COUNTER_SIGNED_DEAL_TERM");
              formData.append("document_name", name);
              formData.append("file", file);
              formData.append("document_reference_date", date);
              formData.append("related_service_order_id", item);
              return serviceOrderService.postServiceOrderDocumentsForClient(formData)
            });
            try {
              await Promise.all(promises);
              setShowDealtermSucess(true);
              setSigned(true);
            } catch (error) {
              console.error("Error uploading documents:", error);
            }
        }else{
          const name = `UNLSORDER${id} Counter Signed Deal Term`;
        const formData = new FormData();
        const date = moment(new Date()).format("YYYY-MM-DD");
        formData.append("document_type", "COUNTER_SIGNED_DEAL_TERM");
        formData.append("document_name", name);
        formData.append("file", file);
        formData.append("document_reference_date", date);
        formData.append("related_service_order_id", id);
        serviceOrderService
          .postServiceOrderDocumentsForClient(formData)
          .then((res) => {
            if (res) {
              if (res.status === 201) {
                setShowDealtermSucess(true);
                setSigned(true);
              } else {
                setLoading(false);
              }
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
          });
        }
      }
  };
  const handleClose = () => {
    setOpenSignaturePopUp(false);
  };

  const downloadPDF = () => {
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `Dealterm.pdf`;
    link.click();
    setLoading(false);
    setOpenSnackbar(true);
    setSeverity("success");
    setSnackbarTitle("Download success");
  };
  return (
    <Root
      style={{
        // maxWidth: "100vw",
        height: "auto",
        // background: '#fff'
      }}
    >
      <Container
        maxWidth={otpverified ? (id?.startsWith('MU') ?'lg' : "md") : "xs"}
        sx={{ width: { xs: "fit-content", md: "auto" } }}
      >
        {!decryptedName ? (
          <Typography textAlign={"center"} p={5}>
            Page not found
          </Typography>
        ) :cancelled ? (
          <Box textAlign={'center'}>
              <img src={Logo} alt="" />
          <Typography textAlign={"center"}>
          Oops, Order has been cancelled.
        </Typography>
            </Box>
        ): otpverified ? (
          <>
            <div
              style={{
                border: "1px solid lightGrey",
                padding: "1rem",
                margin: "1rem",
                width:id?.startsWith('MU') ? '1000px': "fit-content",
              }}
              dangerouslySetInnerHTML={{ __html: template }}
            ></div>
            {signed ? (
              <button className={classes.buyBtn} onClick={downloadPDF}>
                Download
              </button>
            ) : (
              <div
                style={
                  {
                    // width:'100vw',
                    //  padding:20,
                    //  display:'flex',
                    //  justifyContent:"end",
                    //  background:'#fff'
                  }
                }
              >
                <button
                  className={classes.buyBtn}
                  onClick={() => {
                    setSignatureLength(0);
                    setOpenSignaturePopUp(true);
                  }}
                >
                  {imageSouce ? "Re-sign" : "Sign now"}
                </button>
                <button
                  className={classes.buyBtn}
                  onClick={applyDigitalSignature}
                >
                  Apply Digital Signature
                </button>

                <button
                  className={classes.buyBtn}
                  disabled={
                    (imageSouce === null &&
                      template?.includes(
                        '<p style="margin-top:3rem;">(Authorized Signatory)</p>'
                      )) ||
                    loading
                  }
                  onClick={() => {
                    // const file = new Blob([template], { type: "application/pdf" });
                    // const fileURL = URL.createObjectURL(file);
                    // const link = document.createElement('a');
                    // link.href = fileURL
                    // link.download = `Invoice.pdf`;
                    // link.click();
                    generateDealTerm();
                  }}
                >
                  Complete
                </button>
              </div>
            )}
          </>
        ) : completed ? (
          <div>
            <Box display="flex" justifyContent={"center"}>
              <img src={Logo} alt="" />
            </Box>
            <p style={{ textAlign: "center" }}>
              Hey {name ? decryptedName : ""},
            </p>
            <p style={{ textAlign: "center" }}>
              {" "}
              Dealterm has been signed already!
            </p>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Box display="flex" justifyContent={"center"}>
              <img src={Logo} alt="" />
            </Box>
            <Typography>Enter OTP sent to email </Typography>
            <TextField
              size="small"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
                setInvalidOtp(false);
              }}
              name="otp"
              placeholder="OTP"
              type="number"
            />
            {invalidOtp ? (
              <Typography style={{ color: "red", fontSize: "12px" }}>
                Invalid Otp
              </Typography>
            ) : null}

            <Box
              textAlign="center"
              display="flex"
              justifyContent={"space-between"}
              gap={2}
            >
              <Button
                onClick={resendOtp}
                disabled={loading || !email}
                variant="outlined"
                sx={{
                  marginTop: "8px",
                  width: "150px",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                Resend OTP
              </Button>
              <Button
                onClick={verifyOtp}
                disabled={otp.length !== 6 || invalidOtp || !email}
                variant="outlined"
                sx={{
                  marginTop: "8px",
                  width: "150px",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                Verify
              </Button>
            </Box>
          </div>
        )}
      </Container>

      <Dialog
        sx={{
          "& .MuiPaper-root": {
            // rotate:'270deg'
          },
        }}
        onClose={handleClose}
        open={openSignaturePopUp}
      >
        <DialogContent style={{ padding: "0 10px", margin: "0" }}>
          <h5 style={{ margin: "10px 0" }}>Add Signature</h5>

          <SignatureCanvas
            ref={signRef}
            penColor="black"
            onEnd={() => {
              const length = signRef?.current?.toDataURL();
              setSignatureLength(length.length);
            }}
            canvasProps={{
              width: "300",
              height: "100",
              style: { border: "1px dashed #000" },
            }}
          />
          {signatureLength < 3000 && signatureLength !== 0 && (
            <Typography sx={{ fontSize: "12px", color: "red" }}>
              Required valid signature
            </Typography>
          )}
          <Root>
            <button
              className={classes.buyBtn}
              onClick={() => {
                signRef.current.clear();
                setSignatureLength(0);
              }}
            >
              Clear
            </button>
            <button
              className={classes.buyBtn}
              disabled={signatureLength < 3000}
              onClick={() => {
                let imageUrl = signRef.current.toDataURL();
                setImageSouce(imageUrl);
                setTemplate(
                  initialValue.replace(
                    '<p style="margin-top:3rem;">(Authorized Signatory)</p>',
                    `<img style="object-fit:contain;height:50px;" src="${imageUrl}" height="30" width="220">
                     <p style="margin-top:5px;">(Authorized Signatory)</p>`
                  )
                );
                setOpenSignaturePopUp(false);
              }}
            >
              Apply
            </button>
          </Root>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        open={showDealtermSucess}
      >
        <DialogContent style={{ textAlign: "center" }}>
          <div
            style={{
              textAlign: "center",
              margin: "1rem",
              // border:"1px dashed black",
              overflow: "none",
            }}
          >
            <h3>Thank you for completing the Dealterm signing.</h3>
          </div>
          <button
            style={{ textAlign: "center" }}
            onClick={() => {
              setShowDealtermSucess(false);
              // window.close()
            }}
          >
            Close
          </button>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
        >
          {snackbarTitle}
        </Alert>
      </Snackbar>
    </Root>
  );
}
