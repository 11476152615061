import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
  TableCell,
  TableRow,
  TableHead,
  Checkbox,
  Typography,
  FormControlLabel,
  TableBody,
  TableContainer,
  Chip,
  OutlinedInput,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  Tooltip,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, FieldArray, getIn, Field } from "formik";
import moment from "moment";
import loadingSpinner from "src/images/loading-spinner.gif";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { getLookUpDataService } from "src/_services/lookUp";
import Autocomplete from "@mui/material/Autocomplete";
import { serviceOrderService } from "src/_services/serviceOrder";
import SnackBar from "src/components/SnackBar/SnackBar";
import Copy from "src/components/Copy/CopyText";
import ExcelJS from "exceljs";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Close from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Add, Download } from "@mui/icons-material";
import compareJSON from "src/components/comparision";
import { NavLink } from "react-router-dom";
import CopytoClipboard from "src/components/ButtonComponents/CopytoClipboard";
import ModalCentered from "src/components/Modals/ModalCentered";
import ImageRotate from "../Profile/Rotate";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from "react-redux";
const PREFIX = "BulkUpdateCommissionReport";

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled("div")(({ theme }) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "2rem",
    maxWidth: "1600px",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    textTransform: "none",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));



const BulkUpdateCommissionReport = (props) => {
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const [loading, setLoading] = React.useState(false);
  const [client, setClient] = React.useState([]);
  const [orders, setOrders] = React.useState(false);
  const [orderList, setOrderList] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [open, setOpen] = React.useState(false);
  const [preview, setPreview] = React.useState(false);
  const [initValues, setInitValues] = React.useState({
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    client: "",
    orders: [],
    bank: [],
    invoiceType: "",
    orderList: orderList,
    checkedAll: false,
    paidDateforAll: "",
    invoiceCopy: "",
    commissionProof: "",
    remarks: "",
    uploadInvoiceAll: "",
    uploadCommissionProofAll: "",
    uploadOthersForAll: "",
    othersCopy: "",
    status: "1",
    filteredOrder: [],
    nonRelatedOrders: [],
    otherDocumentName: "",
    tagName: "",
    // ownedBy: { id: props.authReducer.auth.user.user_unique_code, label: props.authReducer.auth.user.display_name },
  });

  // const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;

  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //     },
  //   },
  // };
  const role = useSelector((state)=> state.authReducer.auth.user.default_role_code)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const CopyText = ({ value, font }) => {
    const [copy, setCopy] = useState(false);

    const onHover = (value) => {
      setCopy(true);
      navigator.clipboard.writeText(value);
    };

    const onLeave = () => {
      setCopy(false);
    };

    return (
      <Tooltip
        placement="right"
        sx={{
          cursor: "copy",
          fontWeight: font ? "bold" : "",
          paddingLeft: font ? "4px" : "",
        }}
        title={copy ? "Copied" : "Click to Copy"}
      >
        <Typography
          component="span"
          onClick={() => onHover(value)}
          onMouseLeave={onLeave}
        >
          {value}
        </Typography>
      </Tooltip>
    );
  };

  useEffect(() => {
    getLookUpDataService
      .getLookUpUser("CHANNEL_PARTNER")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_code.toUpperCase()} )`,
              };
            });

            setClient(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }, []);

  const type = [
    {
      value: 3,
      lable: "All",
    },
    {
      value: 1,
      lable: "Buy",
    },
    {
      value: 2,
      lable: "Sell",
    },
  ];
  const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
    // eslint-disable-line
    return <NavLink to={props.to} {...props} />; // eslint-disable-line
  });

  const selectOption = (
    value,
    multiple,
    options,
    setFieldValue,
    name,
    label,
    touched,
    errors
  ) => {
    return (
      <Grid item xs={12}>
        <Autocomplete
          multiple={multiple}
          id="client"
          // name="investorOrVendor"
          value={value}
          options={options}
          onChange={async (e, value) => {
            if (value !== null) {
              setFieldValue(name, value);
            } else {
              setFieldValue(name, "");
            }
          }}
          disabled={orders}
          getOptionLabel={(option) => option.lable || ""}
          renderInput={(params) => (
            <TextField
              error={Boolean(touched && errors)}
              helperText={touched && errors}
              {...params}
              label={label}
            />
          )}
        />
        <ErrorFocus />
      </Grid>
    );
  };

  function extractInfo(data) {
    const clientInfoRegex = /(.*)\s\((.*)\)/;
    const clientMatch = data.client_name?.match(clientInfoRegex);

    if (clientMatch) {
      data.client_name = clientMatch[1];
      data.client_id = clientMatch[2];
    }

    const partnerInfoRegex = /(.*)\s\((.*)\)/;
    const partnerMatch = data.channel_partner.match(partnerInfoRegex);

    if (partnerMatch) {
      data.channel_partner = partnerMatch[1];
      data.channel_partner_id = partnerMatch[2];
    }

    return data;
  }

  const handleDownload = async (value, orders) => {
    let data = orders
      ? value.map((item) => {
          return {
            service_id: item,
          };
        })
      : value.map((item) => extractInfo(item));
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("OrderDetails");
    // Define header row
    worksheet.columns = orders
      ? [{ header: "Order Id", key: "service_id", width: 20 }]
      : [
          { header: "Order Id", key: "service_id", width: 20 },
          { header: "Order Type", key: "order_type", width: 15 },
          { header: "Order Status", key: "order_status", width: 15 },
          { header: "Channel Partner", key: "channel_partner", width: 20 },
          {
            header: "Channel Partner PAN",
            key: "channel_partner_id",
            width: 25,
          },
          { header: "Client Name", key: "client_name", width: 20 },
          { header: "Client PAN", key: "client_id", width: 15 },
          { header: "Scrip Name", key: "scrip_name", width: 30 },
          { header: "Quantity", key: "quantity", width: 10 },
          { header: "Commission Value", key: "commission_value", width: 20 },
          {
            header: "Commission Paid Status",
            key: "commission_paid_status",
            width: 25,
          },
          {
            header: "Commission Paid Date",
            key: "commission_paid_date",
            width: 25,
          },
        ];

    // Add data rows
    orders
      ? data.forEach((item) => {
          worksheet.addRow({
            service_id: item.service_id,
          });
        })
      : data.forEach((item) => {
          worksheet.addRow({
            service_id: item.service_id,
            order_type: item.order_type,
            order_status: item.order_status,
            channel_partner: item.channel_partner,
            channel_partner_id: item.channel_partner_id,
            client_name: item.client_name,
            client_id: item.client_id,
            scrip_name: item.scrip_name,
            quantity: item.quantity,
            commission_value: Number(item.commission_value),
            commission_paid_status: item.commission_paid_status ? "Yes" : "No",
            commission_paid_date: item.commission_paid_date,
          });
        });

    // Style the header row
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "#000" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
      };
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    // Merge cells for "Order Id" column based on identical values
    let startRow = 2;
    for (let i = 2; i <= data.length + 1; i++) {
      if (
        worksheet.getRow(i).getCell(1).value !==
        worksheet.getRow(startRow).getCell(1).value
      ) {
        if (i - 1 > startRow) {
          worksheet.mergeCells(`A${startRow}:A${i - 1}`);
        }
        startRow = i;
      }
    }
    if (startRow < data.length + 1) {
      worksheet.mergeCells(`A${startRow}:A${data.length + 1}`);
    }

    // Generate Excel file and download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Commission_reports_${
      orders ? "non_related_order_list" : data[0].channel_partner
    }.xlsx`;
    link.click();
  };

  const getOrders = (values) => {
    let orderId = [];
    if (values.tagName?.includes(" ")) {
      let orderArray = values.tagName
        .split(" ")
        .map((item) => item?.trim().replace(",", ""));
      orderId = [...tags, ...orderArray];
    } else {
      orderId = [...tags, values.tagName];
    }
    const data = {
      order_id: values.tagName?.trim() !== "" ? orderId : tags,
      order_type: values.invoiceType.value,
      client_id: values.client.id,
      from_date: values.fromDate,
      to_date: values.toDate,
      status: values.status,
    };
    serviceOrderService.getCommissionData(data).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          let filteredArray = res.data.flatMap(
            (item) =>
              item.commission_split_data !== null &&
              item.commission_split_data
                .filter(
                  (data) =>
                    data.related_user_id === values.client.id &&
                    (values.status === "1"
                      ? data.commission_paid_status === false ||
                        data.commission_paid_status === true
                      : values.status === "2"
                      ? data.commission_paid_status === true
                      : data.commission_paid_status === false)
                )
                .map((filteredData) => {
                  const commissionValue =
                    item?.order_type === "Sell"
                      ? parseFloat(
                          filteredData.scrip_total_price -
                            filteredData.scrip_commission_price *
                              filteredData.quantity
                        ).toFixed(3)
                      : parseFloat(
                          filteredData.scrip_commission_price *
                            filteredData.quantity -
                            filteredData.scrip_total_price
                        ).toFixed(3);
                  const newCommissionValue =
                    (commissionValue * filteredData.commission_percentage) /
                    100;
                  return {
                    id: filteredData.id,
                    commission_paid_date: filteredData.commission_paid_date,
                    commission_paid_status: filteredData.commission_paid_status,
                    service_id: item.service_id,
                    commission_value: parseFloat(newCommissionValue).toFixed(2),
                    client_name: filteredData.vendor || filteredData.investor,
                    channel_partner: filteredData.name,
                    uploadAll: false,
                    uploadInvoice: false,
                    scrip_name: filteredData.scrip_name,
                    quantity: filteredData.quantity,
                    uploadOthers: false,
                    uploadCommissionProof: false,
                    order_id: item.id,
                    order_status: item.order_status,
                    order_type: item.order_type,
                    transaction_id: filteredData.transaction_id,
                    verified_status:item.verified_status
                  };
                })
          );
          let sortedArray = filteredArray.sort((b, a) => {
            return (
              getServiceNumber(a.service_id) - getServiceNumber(b.service_id)
            );
          });

          const resultedArray = filteredArray.map((item) => item.service_id);
          const set2 = new Set(resultedArray);

          const nonRelatedOrders =
            orderId.length > 0 ? orderId.filter((item) => !set2.has(item)) : [];
          const checkedAll = filteredArray.some(
            (item) => item.commission_paid_status === false
          );
          if (filteredArray.length === 0) {
            setSnackbarTitle("No orders found");
            setSeverity("success");
            setOpen(true);
          } else {
            setOrders(true);
            const totalCommission = filteredArray.reduce(
              (sum, item) => Number(sum) + Number(item.commission_value),
              0
            );
            setInitValues({
              fromDate: values.fromDate,
              toDate: values.toDate,
              client: values.client,
              orders: [],
              bank: [],
              order_type: values.order_type,
              invoiceType: values.invoiceType,
              orderList: sortedArray,
              checkedAll: !checkedAll,
              paidDateforAll: "",
              invoiceCopy: "",
              commissionProof: "",
              remarks: "",
              uploadInvoiceAll: "",
              uploadCommissionProofAll: "",
              othersCopy: "",
              uploadOthersForAll: "",
              totalCommission: parseFloat(totalCommission).toFixed(2),
              status: "1",
              filteredOrder: [],
              otherDocumentName: "",
              tagName: "",
              verifiedStatus:sortedArray[0].verified_status,
              nonRelatedOrders: nonRelatedOrders.filter(
                (subArray) => subArray.length > 0
              ),
            });
            setOrderList(sortedArray);
          }
        } else {
          setSnackbarTitle("No orders found");
          setSeverity("success");
          setOpen(true);
        }
      } else {
        setSnackbarTitle("Something went wrong");
        setSeverity("error");
        setOpen(true);
      }
    });
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  const handleAddTag = (e, setFieldValue) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      e.preventDefault();
      if (e.target.value.includes(" ")) {
        let orderArray = e.target.value
          .split(" ")
          .map((item) => item.trim().replace(",", ""));
        setTags([...tags, ...orderArray]);
      } else {
        setTags([...tags, e.target.value]);
      }
      e.target.value = "";
    }
    setFieldValue("tagName", "");
  };

  const handleDelete = (i) => {
    setTags((tag) => tag.filter((name, index) => index !== i));
  };

  const handleDeleteOrder = (values, item, setFieldValue) => {
    let array = values.filteredOrder;
    const filteredItems = values.orderList.filter(
      (items) => items.transaction_id !== item.transaction_id
    );
    array.push(item);
    const totalCommission = filteredItems.reduce(
      (sum, item) => Number(sum) + Number(item.commission_value),
      0
    );
    setFieldValue("totalCommission", parseFloat(totalCommission).toFixed(2));
    setFieldValue("orderList", filteredItems);
    setFieldValue("filteredOrder", array);
  };
  function getServiceNumber(serviceId) {
    return parseInt(serviceId.replace(/\D/g, ""), 10);
  }

  // Sorting the array based on the numeric part of service_id

  const handleAddOrders = (values, item, setFieldValue) => {
    let array = values.orderList;
    const filteredItems = values.filteredOrder.filter(
      (items) => items.transaction_id !== item.transaction_id
    );
    array.push(item);
    const totalCommission = array.reduce(
      (sum, item) => Number(sum) + Number(item.commission_value),
      0
    );
    const sortedData = array.sort((a, b) => {
      return getServiceNumber(a.service_id) - getServiceNumber(b.service_id);
    });
    setFieldValue("totalCommission", parseFloat(totalCommission).toFixed(2));
    setFieldValue("orderList", sortedData);
    setFieldValue("filteredOrder", filteredItems);
  };

  const [hover, setHover] = useState(false);
  const [rowindex, setrowIndex] = React.useState("");
  const onHover = () => {
    setHover(true);
  };

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          fromDate: Yup.string().required("Required"),
          toDate: Yup.string().required("Required"),
          client: Yup.string().required("Required"),
          invoiceType: Yup.string().required("Required"),
          orderList: Yup.array(
            Yup.object({
              commission_paid_date: Yup.string().when(
                "commission_paid_status",
                {
                  is: true,
                  then: Yup.string().required("Commission date required"),
                  otherwise: Yup.string().nullable(),
                }
              ),
            })
          ),
          commissionProof: Yup.mixed().when("orderList", {
            is: (type) => type.some((i) => i.uploadCommissionProof === true), //just an e.g. you can return a function
            then: Yup.mixed()
              .required("A file is required")
              .test(
                "fileFormat",
                "Unsupported Format",
                (value) => value && SUPPORTED_FORMATS.includes(value.type)
              )
              .test(
                "fileSize",
                "Maximum file size is 10MB",
                (value) => value && value.size < 10242880
              ),
            otherwise: Yup.mixed(),
          }),
          invoiceCopy: Yup.mixed().when("orderList", {
            is: (type) => type.some((i) => i.uploadInvoice === true), //just an e.g. you can return a function
            then: Yup.mixed()
              .required("A file is required")
              .test(
                "fileFormat",
                "Unsupported Format",
                (value) => value && SUPPORTED_FORMATS.includes(value.type)
              )
              .test(
                "fileSize",
                "Maximum file size is 10MB",
                (value) => value && value.size < 10242880
              ),
            otherwise: Yup.mixed(),
          }),
          othersCopy: Yup.mixed().when("orderList", {
            is: (type) => type.some((i) => i.uploadOthers === true), //just an e.g. you can return a function
            then: Yup.mixed()
              .required("A file is required")
              .test(
                "fileFormat",
                "Unsupported Format",
                (value) => value && SUPPORTED_FORMATS.includes(value.type)
              )
              .test(
                "fileSize",
                "Maximum file size is 10MB",
                (value) => value && value.size < 10242880
              ),
            otherwise: Yup.mixed(),
          }),
          otherDocumentName: Yup.string().when("orderList", {
            is: (type) => type.some((i) => i.uploadOthers === true), //just an e.g. you can return a function
            then: Yup.string().required("Document name required"),
            otherwise: Yup.string(),
          }),
        })}
        onSubmit={(values) => {
          setLoading(true);
          const newValue = values.orderList.map((item) => {
            const match = initValues.orderList.find(
              (item2) => item2.transaction_id === item.transaction_id
            );
            let result = compareJSON(item, match);
            result.push({
              field: "Name",
              oldValue: "",
              newValue: item.channel_partner,
            });
            return {
              ...item,
              updated_values: JSON.stringify(result),
            };
          });
          values = { ...values, orderList: newValue };
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container spacing={2}>
                {orders ? (
                  <>
                    {values.nonRelatedOrders.length > 0 ? (
                      <Grid item xs={12} p={0} display="flex">
                        <Typography>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              lineHeight: "20px",
                              color: "red",
                            }}
                            component={"span"}
                          >
                            Non related order List :
                          </Typography>
                          <Typography
                            ml={1}
                            component={"span"}
                            sx={{ cursor: "copy" }}
                          >
                            {values.nonRelatedOrders.map((item, index) => {
                              let orderId = item.replace("UNLSORDER", "");
                              return (
                                <span direction={"row"}>
                                  <Typography
                                    variant="body2"
                                    component={"span"}
                                    noWrap
                                    // to={``}
                                    onDoubleClick={() => {
                                      window.open(
                                        `/app/service-order-details/${orderId}`,
                                        "_blank"
                                      );
                                      // navigate()
                                    }}
                                    color="primary"
                                    target="_blank"
                                    style={{
                                      textTransform: "capitalize",
                                      maxWidth: 300,
                                    }}
                                  >
                                    <CopyText font="true" value={item} />
                                  </Typography>
                                  {index === values.nonRelatedOrders.length - 1
                                    ? ""
                                    : ", "}
                                </span>
                              );
                            })}
                            <IconButton
                              sx={{ padding: "2px 8px" }}
                              onClick={() =>
                                handleDownload(values.nonRelatedOrders, true)
                              }
                            >
                              <Download />
                            </IconButton>
                          </Typography>
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid
                      item
                      xs={12}
                      p={0}
                      display="flex"
                      justifyContent={"space-between"}
                    >
                      <Typography sx={{ display: "flex" ,fontWeight:'bold'}}>
                        Channel Partner :{" "}
                        <Typography
                          variant="body2"
                          // component={LinkBtn}
                          noWrap
                          // to={``}
                          onDoubleClick={() => {
                            window.open(
                              `/app/user-details/${values.client.id}`,
                              "_blank"
                            );
                            // navigate()
                          }}
                          color="primary"
                          target="_blank"
                          style={{ textTransform: "capitalize", maxWidth: 300 }}
                        >
                          <Copy font="true" value={values.client.lable} />
                        </Typography>
                          {values.verifiedStatus ? (values.verifiedStatus ===1 ?  <CheckCircleOutlineIcon
                  style={{ color: "green",fontSize:'22px',paddingLeft:'4px'}}
                /> : values.verifiedStatus ===2 ?   <ErrorIcon style={{ color: "#ffc185" ,paddingLeft:'4px',fontSize:'22px'}} /> :   <CancelIcon color={"error"} style={{ paddingLeft:'4px',fontSize:'22px'}} />):null}
                      </Typography>
                      <Button
                        onClick={() => handleDownload(initValues.orderList)}
                        sx={{ textTransform: "none" }}
                      >
                        Download Report
                      </Button>
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      sx={{ alignItems: "center", paddingLeft: "1rem" }}
                    >
                      <Grid item xs={values.checkedAll ? 2 : 4} p={0}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.checkedAll}
                              //  name={`orderList`}
                              //  id="commissionStatus"
                              //  aria-describedby="outlined-weight-helper-text"
                              onBlur={handleBlur}
                              onChange={(e, value) => {
                                setFieldValue("checkedAll", value);
                                if (!value) {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      const match = initValues.orderList.find(
                                        (item2) => item2.id === value.id
                                      );
                                      return {
                                        ...value,
                                        commission_paid_date:
                                          value.commission_paid_date,
                                        commission_paid_status:
                                          match.commission_paid_status,
                                      };
                                    }
                                  );
                                  setFieldValue(`orderList`, updateAll);
                                  setFieldValue("checkedAll", false);
                                } else {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      return {
                                        ...value,
                                        commission_paid_status: true,
                                      };
                                    }
                                  );
                                  setFieldValue(`orderList`, updateAll);
                                }
                              }}
                              color="primary"
                            />
                          }
                          label={
                            <Typography component={"span"}>
                              Apply paid all
                            </Typography>
                          }
                        />
                      </Grid>
                      {values.checkedAll && (
                        <Grid item xs={4} md={2} p={0}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="paidDateforAll"
                            label="Paid Date for all"
                            type="date"
                            name="paidDateforAll"
                            value={values.paidDateforAll}
                            InputProps={{
                              inputProps: {
                                max: `${moment(new Date()).format(
                                  "YYYY-MM-DD"
                                )}`,
                              },
                            }}
                            error={Boolean(
                              touched.paidDateforAll && errors.paidDateforAll
                            )}
                            helperText={
                              touched.paidDateforAll && errors.paidDateforAll
                            }
                            onChange={(e) => {
                              setFieldValue("paidDateforAll", e.target.value);
                              let updateAll = values.orderList.map((value) => {
                                return {
                                  ...value,
                                  commission_paid_date: value.commission_paid_date
                                    ? value.commission_paid_date
                                    : e.target.value,
                                };
                              });
                              setFieldValue(`orderList`, updateAll);
                            }}
                            onBlur={handleBlur}
                            // InputProps={{ inputProps: { min: `${moment(values.fromDate).format("YYYY-MM-DD")}` } }}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={6}
                        p={0}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.uploadInvoiceAll}
                              //  name={`orderList`}
                              //  id="commissionStatus"
                              //  aria-describedby="outlined-weight-helper-text"
                              onBlur={handleBlur}
                              onChange={(e, value) => {
                                setFieldValue("uploadInvoiceAll", value);
                                if (!value) {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      return {
                                        ...value,
                                        uploadInvoice: false,
                                        uploadAll: false,
                                      };
                                    }
                                  );
                                  setFieldValue(`orderList`, updateAll);
                                  setFieldValue("uploadInvoiceAll", false);
                                } else {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      return {
                                        ...value,
                                        uploadInvoice: true,
                                        uploadAll:
                                          values.uploadCommissionProofAll &&
                                          values.uploadOthersForAll
                                            ? true
                                            : value.uploadAll,
                                      };
                                    }
                                  );
                                  setFieldValue(`orderList`, updateAll);
                                }
                              }}
                              color="primary"
                            />
                          }
                          label={
                            <Typography component={"span"}>
                              Upload invoice for All
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.uploadCommissionProofAll}
                              //  name={`orderList`}
                              //  id="commissionStatus"
                              //  aria-describedby="outlined-weight-helper-text"
                              onBlur={handleBlur}
                              onChange={(e, value) => {
                                setFieldValue(
                                  "uploadCommissionProofAll",
                                  value
                                );
                                if (!value) {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      return {
                                        ...value,
                                        uploadCommissionProof: false,
                                        uploadAll: false,
                                      };
                                    }
                                  );
                                  setFieldValue(`orderList`, updateAll);
                                  setFieldValue(
                                    "uploadCommissionProofAll",
                                    false
                                  );
                                } else {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      return {
                                        ...value,
                                        uploadCommissionProof: true,
                                        uploadAll:
                                          values.uploadInvoiceAll &&
                                          values.uploadOthersForAll
                                            ? true
                                            : value.uploadAll,
                                      };
                                    }
                                  );

                                  setFieldValue(`orderList`, updateAll);
                                }
                              }}
                              color="primary"
                            />
                          }
                          label={
                            <Typography component={"span"}>
                              Upload commission proof for All
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.uploadOthersForAll}
                              //  name={`orderList`}
                              //  id="commissionStatus"
                              //  aria-describedby="outlined-weight-helper-text"
                              onBlur={handleBlur}
                              onChange={(e, value) => {
                                setFieldValue("uploadOthersForAll", value);
                                if (!value) {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      return {
                                        ...value,
                                        uploadOthers: false,
                                        uploadAll: false,
                                      };
                                    }
                                  );
                                  setFieldValue(`orderList`, updateAll);
                                  setFieldValue("uploadOthersForAll", false);
                                } else {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      return {
                                        ...value,
                                        uploadOthers: true,
                                        uploadAll:
                                          values.uploadInvoiceAll &&
                                          values.uploadCommissionProofAll
                                            ? true
                                            : value.uploadAll,
                                      };
                                    }
                                  );

                                  setFieldValue(`orderList`, updateAll);
                                }
                              }}
                              color="primary"
                            />
                          }
                          label={
                            <Typography component={"span"}>
                              Upload Others for All
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} p={0}>
                      <TableContainer sx={{ scrollbarWidth: "none" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Sl No
                            </TableCell>
                            <TableCell>Order Id</TableCell>
                            <TableCell>Order Type</TableCell>
                            <TableCell>Order Status</TableCell>
                            <TableCell>Scrip Name</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Commission status</TableCell>
                            <TableCell>Commission Paid date</TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Upload Files
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <FieldArray name="orderList">
                            {() =>
                              values.orderList?.map((_, index) => {
                                const touchedCommissionPaidDate = getIn(
                                  touched,
                                  `orderList.${index}.commission_paid_date`
                                );
                                const errorCommissionPaidDate = getIn(
                                  errors,
                                  `orderList.${index}.commission_paid_date`
                                );
                                return (
                                  <TableRow key={index}>
                                    {/** both these conventions do the same */}
                                    <TableCell>{index + 1}</TableCell>

                                    <TableCell
                                      sx={{
                                        display: "flex",
                                        gap: 1,
                                        width: "170px",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        padding: "18px 0px",
                                      }}
                                      onMouseEnter={() => {
                                        onHover();
                                        setrowIndex(
                                          values.orderList[index].transaction_id
                                        );
                                      }}
                                      onMouseLeave={() => setrowIndex()}
                                    >
                                      <Typography
                                        component={LinkBtn}
                                        to={`/app/service-order-details/${values.orderList[index].order_id}`}
                                        color="primary"
                                      >
                                        <Copy
                                          value={
                                            values.orderList[index].service_id
                                          }
                                        />
                                      </Typography>
                                      {rowindex ===
                                        values.orderList[index]
                                          .transaction_id && (
                                        <Tooltip
                                          style={{ cursor: "copy" }}
                                          title={
                                            hover ? "Copied" : "Click to Copy"
                                          }
                                        >
                                          <CopytoClipboard
                                            userBasicInfo={
                                              values.orderList[index].service_id
                                            }
                                            state={hover}
                                          />
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {values.orderList[index].order_type}
                                    </TableCell>
                                    <TableCell>
                                      {values.orderList[index].order_status}
                                    </TableCell>
                                    <TableCell sx={{ textWrap: "nowrap" }}>
                                      {values.orderList[index].scrip_name}
                                    </TableCell>
                                    <TableCell>
                                      {values.orderList[index].quantity}
                                    </TableCell>
                                    <TableCell>
                                      <Copy
                                        value={
                                          values.orderList[index]
                                            .commission_value
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.orderList[index]
                                                .commission_paid_status
                                            }
                                            name={`orderList.${index}.commission_paid_status`}
                                            id="commissionStatus"
                                            aria-describedby="outlined-weight-helper-text"
                                            onBlur={handleBlur}
                                            onChange={(e, value) => {
                                              setFieldValue(
                                                `orderList.${index}.commission_paid_status`,
                                                value
                                              );
                                              if (!value) {
                                                setFieldValue(
                                                  `orderList.${index}.commission_paid_date`,
                                                  ""
                                                );
                                              }
                                            }}
                                            color="primary"
                                          />
                                        }
                                        label={
                                          <Typography component={"span"}>
                                            Paid
                                          </Typography>
                                        }
                                      />
                                    </TableCell>

                                    <TableCell sx={{ padding: "13px" }}>
                                      {values.orderList[index]
                                        .commission_paid_status && (
                                        <TextField
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          //  InputProps={{ disableUnderline: true, }}
                                          style={{ marginTop: "8px" }}
                                          label="Commission Paid Date*"
                                          id="investorcolor"
                                          variant="outlined"
                                          type="date"
                                          size="small"
                                          disabled={
                                            !values.orderList[index]
                                              .commission_paid_status
                                          }
                                          name={`orderList.${index}.commission_paid_date`}
                                          value={
                                            values.orderList[index]
                                              .commission_paid_date
                                          }
                                          autoComplete="off"
                                          fullWidth
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={Boolean(
                                            touchedCommissionPaidDate &&
                                              errorCommissionPaidDate
                                          )}
                                          helperText={
                                            touchedCommissionPaidDate &&
                                            errorCommissionPaidDate
                                          }
                                        />
                                      )}
                                    </TableCell>
                                    <TableCell sx={{ display: "flex" }}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.orderList[index].uploadAll
                                            }
                                            name={`orderList.${index}.uploadAll`}
                                            id="commissionStatus"
                                            aria-describedby="outlined-weight-helper-text"
                                            onBlur={handleBlur}
                                            onChange={(e, value) => {
                                              setFieldValue(
                                                `orderList.${index}.uploadAll`,
                                                value
                                              );
                                              setFieldValue(
                                                `orderList.${index}.uploadInvoice`,
                                                value
                                              );
                                              setFieldValue(
                                                `orderList.${index}.uploadCommissionProof`,
                                                value
                                              );
                                              setFieldValue(
                                                `orderList.${index}.uploadOthers`,
                                                value
                                              );
                                              if (!value) {
                                                setFieldValue(
                                                  "uploadCommissionProofAll",
                                                  false
                                                );
                                                setFieldValue(
                                                  "uploadInvoiceAll",
                                                  false
                                                );
                                                setFieldValue(
                                                  "uploadOthers",
                                                  false
                                                );
                                              }
                                            }}
                                            color="primary"
                                          />
                                        }
                                        label={
                                          <Typography component={"span"}>
                                            All
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.orderList[index]
                                                .uploadInvoice
                                            }
                                            name={`orderList.${index}.uploadInvoice`}
                                            id="uploadInvoice"
                                            aria-describedby="outlined-weight-helper-text"
                                            onBlur={handleBlur}
                                            onChange={(e, value) => {
                                              setFieldValue(
                                                `orderList.${index}.uploadInvoice`,
                                                value
                                              );
                                              if (!value) {
                                                setFieldValue(
                                                  `orderList.${index}.uploadAll`,
                                                  value
                                                );
                                                setFieldValue(
                                                  "uploadInvoiceAll",
                                                  false
                                                );
                                              } else if (
                                                values.orderList[index]
                                                  .uploadCommissionProof &&
                                                values.orderList[index]
                                                  .uploadOthers
                                              ) {
                                                setFieldValue(
                                                  `orderList.${index}.uploadAll`,
                                                  value
                                                );
                                              }
                                            }}
                                            color="primary"
                                          />
                                        }
                                        label={
                                          <Typography component={"span"}>
                                            Invoice
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.orderList[index]
                                                .uploadCommissionProof
                                            }
                                            name={`orderList.${index}.uploadCommissionProof`}
                                            id="uploadCommissionProof"
                                            aria-describedby="outlined-weight-helper-text"
                                            onBlur={handleBlur}
                                            onChange={(e, value) => {
                                              setFieldValue(
                                                `orderList.${index}.uploadCommissionProof`,
                                                value
                                              );
                                              if (!value) {
                                                setFieldValue(
                                                  `orderList.${index}.uploadAll`,
                                                  value
                                                );
                                                setFieldValue(
                                                  "uploadCommissionProofAll",
                                                  false
                                                );
                                              } else if (
                                                values.orderList[index]
                                                  .uploadInvoice &&
                                                values.orderList[index]
                                                  .uploadOthers
                                              ) {
                                                setFieldValue(
                                                  `orderList.${index}.uploadAll`,
                                                  value
                                                );
                                              }
                                            }}
                                            color="primary"
                                          />
                                        }
                                        label={
                                          <Typography
                                            component={"span"}
                                            sx={{ whiteSpace: "nowrap" }}
                                          >
                                            Commission Proof
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.orderList[index]
                                                .uploadOthers
                                            }
                                            name={`orderList.${index}.uploadOthers`}
                                            id="uploadOthers"
                                            aria-describedby="outlined-weight-helper-text"
                                            onBlur={handleBlur}
                                            onChange={(e, value) => {
                                              setFieldValue(
                                                `orderList.${index}.uploadOthers`,
                                                value
                                              );
                                              if (!value) {
                                                setFieldValue(
                                                  `orderList.${index}.uploadAll`,
                                                  value
                                                );
                                                setFieldValue(
                                                  "uploadOthersForAll",
                                                  false
                                                );
                                              } else if (
                                                values.orderList[index]
                                                  .uploadInvoice &&
                                                values.orderList[index]
                                                  .uploadCommissionProof
                                              ) {
                                                setFieldValue(
                                                  `orderList.${index}.uploadAll`,
                                                  value
                                                );
                                              }
                                            }}
                                            color="primary"
                                          />
                                        }
                                        label={
                                          <Typography
                                            component={"span"}
                                            sx={{ whiteSpace: "nowrap" }}
                                          >
                                            Others
                                          </Typography>
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <IconButton
                                        onClick={() =>
                                          handleDeleteOrder(
                                            values,
                                            values.orderList[index],
                                            setFieldValue
                                          )
                                        }
                                      >
                                        <Close />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            }
                          </FieldArray>
                        </TableBody>
                      </TableContainer>
                    </Grid>
                    <Grid container sx={{ display: "flex", marginY: "1rem" }}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            margin: "8px 1rem",
                            display: "flex",
                          }}
                        >
                          <Typography> Total Commission Amount : </Typography>
                          <Copy font={"true"} value={values.totalCommission} />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        textAlign={"end"}
                        onClick={() => handleDownload(values.orderList)}
                      >
                        <Button sx={{ textTransform: "none" }}>
                          Download updated report
                        </Button>
                      </Grid>
                    </Grid>
                    {values.filteredOrder.length > 0 ? (
                      <Grid item xs={12}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            Hidden orders
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer sx={{ scrollbarWidth: "none" }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                                    Sl No
                                  </TableCell>
                                  <TableCell>Order Id</TableCell>
                                  <TableCell>Order Type</TableCell>
                                  <TableCell>Order Status</TableCell>
                                  <TableCell>Amount</TableCell>
                                  <TableCell>Commission Paid status</TableCell>
                                  <TableCell>Commission Paid date</TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <FieldArray name="filteredOrder">
                                  {() =>
                                    values.filteredOrder?.map((_, index) => {
                                      return (
                                        <TableRow key={index}>
                                          {/** both these conventions do the same */}
                                          <TableCell>{index + 1}</TableCell>

                                          <TableCell>
                                            <Copy
                                              value={
                                                values.filteredOrder[index]
                                                  .service_id
                                              }
                                            />
                                          </TableCell>
                                          <TableCell>
                                            {
                                              values.filteredOrder[index]
                                                .order_type
                                            }
                                          </TableCell>
                                          <TableCell>
                                            {
                                              values.filteredOrder[index]
                                                .order_status
                                            }
                                          </TableCell>
                                          <TableCell>
                                            <Copy
                                              value={
                                                values.filteredOrder[index]
                                                  .commission_value
                                              }
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Copy
                                              value={
                                                values.filteredOrder[index]
                                                  .commission_paid_status
                                                  ? "Paid"
                                                  : "Unpaid"
                                              }
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Copy
                                              value={
                                                values.filteredOrder[index]
                                                  .commission_paid_date
                                              }
                                            />
                                          </TableCell>

                                          <TableCell>
                                            <IconButton
                                              onClick={() =>
                                                handleAddOrders(
                                                  values,
                                                  values.filteredOrder[index],
                                                  setFieldValue
                                                )
                                              }
                                            >
                                              <Add />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })
                                  }
                                </FieldArray>
                              </TableBody>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    ) : null}
                    <Grid item xs={6} p={0}>
                      <Typography className={classes.label}>
                        Commission Proof{" "}
                      </Typography>
                      <OutlinedInput
                        accept="image/*"
                        // className={classes.input}
                        id="icon-button-file"
                        name="commissionProof"
                        fullWidth={true}
                        onChange={(e) => {
                          setFieldValue("commissionProof", e.target.files[0]);
                        }}
                        type="file"
                      />
                      {values.commissionProof && !errors.commissionProof ? (
                        <FormHelperText
                          onClick={() => {
                            const url = URL.createObjectURL(
                              values.commissionProof
                            );
                            setPreview({
                              url: url,
                              type:
                                values.commissionProof.type ===
                                "application/pdf"
                                  ? true
                                  : false,
                            });
                          }}
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          {"Preview"}
                        </FormHelperText>
                      ) : (
                        <FormHelperText
                          style={{ color: "#F44336", paddingLeft: 16 }}
                        >
                          {errors.commissionProof}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6} p={0}>
                      <Typography className={classes.label}>
                        Invoice Proof
                      </Typography>
                      <OutlinedInput
                        accept="image/*"
                        // className={classes.input}
                        id="icon-button-file"
                        name="invoiceCopy"
                        fullWidth={true}
                        onChange={(e) => {
                          setFieldValue("invoiceCopy", e.target.files[0]);
                        }}
                        type="file"
                      />

                      {values.invoiceCopy && !errors.invoiceCopy ? (
                        <FormHelperText
                          onClick={() => {
                            const url = URL.createObjectURL(values.invoiceCopy);
                            setPreview({
                              url: url,
                              type:
                                values.invoiceCopy.type === "application/pdf"
                                  ? true
                                  : false,
                            });
                          }}
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          {"Preview"}
                        </FormHelperText>
                      ) : (
                        <FormHelperText
                          style={{ color: "#F44336", paddingLeft: 16 }}
                        >
                          {errors.invoiceCopy}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6} p={0}>
                      <Typography className={classes.label}>
                        Other Document{" "}
                      </Typography>
                      <OutlinedInput
                        accept="image/*"
                        // className={classes.input}
                        id="icon-button-file"
                        name="othersCopy"
                        fullWidth={true}
                        onChange={(e) => {
                          setFieldValue("othersCopy", e.target.files[0]);
                        }}
                        type="file"
                      />
                      {values.othersCopy && !errors.othersCopy ? (
                        <FormHelperText
                          onClick={() => {
                            const url = URL.createObjectURL(values.othersCopy);
                            setPreview({
                              url: url,
                              type:
                                values.othersCopy.type === "application/pdf"
                                  ? true
                                  : false,
                            });
                          }}
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          {"Preview"}
                        </FormHelperText>
                      ) : (
                        <FormHelperText
                          style={{ color: "#F44336", paddingLeft: 16 }}
                        >
                          {errors.othersCopy}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6} p={0}>
                      <Typography className={classes.label}>
                        Other Document Name
                      </Typography>
                      <TextField
                        fullWidth
                        label=""
                        name="otherDocumentName"
                        onChange={handleChange}
                        value={values.otherDocumentName}
                        variant="outlined"
                        error={Boolean(
                          touched.otherDocumentName && errors.otherDocumentName
                        )}
                        helperText={
                          touched.otherDocumentName && errors.otherDocumentName
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography>Remarks</Typography>
                      <Field
                        name="remarks"
                        render={({ field, form }) => {
                          return (
                            <CKEditor
                              editor={ClassicEditor}
                              data={field.value}
                              config={{
                                toolbar: [
                                  "heading",
                                  "|",
                                  "bold",
                                  "italic",
                                  "blockQuote",
                                  "numberedList",
                                  "bulletedList",
                                  "insertTable",
                                  "|",
                                  "undo",
                                  "redo",
                                ],
                              }}
                              onChange={(e, editor) => {
                                form.setFieldValue("remarks", editor.getData());
                              }}
                            />
                          );
                        }}
                      />
                      <FormHelperText
                        style={{ color: errors.remarks && "#F44336" }}
                      >
                        {touched.remarks && errors.remarks}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        color="primary"
                        disabled={isSubmitting || role ==="SALES"}
                        className={classes.popDownBtn}
                        size="medium"
                        type="submit"
                        variant="contained"
                      >
                        {loading === false ? (
                          <> {"Update"}</>
                        ) : (
                          <>
                            <img
                              alt="loader"
                              src={loadingSpinner}
                              className={classes.buttonProgress}
                            />{" "}
                            Update{" "}
                          </>
                        )}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      {selectOption(
                        values.type,
                        false,
                        type,
                        setFieldValue,
                        "invoiceType",
                        "Order Type",
                        touched.invoiceType,
                        errors.invoiceType
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {selectOption(
                        values.client,
                        false,
                        client,
                        setFieldValue,
                        "client",
                        "Channel Partner",
                        touched.client,
                        errors.client
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        helperText=""
                        label={`Search by order id`}
                        onKeyPress={(e) => handleAddTag(e, setFieldValue)}
                        name="tagName"
                        disabled={orders}
                        onChange={handleChange}
                      />
                      <div className={classes.wrap}>
                        {tags &&
                          tags.map((item, index) => (
                            <Chip
                              disabled={orders}
                              style={{ margin: "4px" }}
                              key={index}
                              tabIndex={-1}
                              label={item}
                              onDelete={() => handleDelete(index)}
                            />
                          ))}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} textAlign={"center"}>
                      <Typography>Or</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="fromDate"
                        label="From Date"
                        type="date"
                        name="fromDate"
                        disabled={orders}
                        error={Boolean(touched.fromDate && errors.fromDate)}
                        helperText={touched.fromDate && errors.fromDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          inputProps: {
                            max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                          },
                        }}
                        value={values.fromDate}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="toDate"
                        label="End Date"
                        type="date"
                        name="toDate"
                        disabled={orders}
                        value={values.toDate}
                        InputProps={{
                          inputProps: {
                            min: values.fromDate,
                            max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                          },
                        }}
                        error={Boolean(touched.toDate && errors.toDate)}
                        helperText={touched.toDate && errors.toDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // InputProps={{ inputProps: { min: `${moment(values.fromDate).format("YYYY-MM-DD")}` } }}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl
                        component="fieldset"
                        error={Boolean(touched.status && errors.status)}
                      >
                        <FormLabel component="legend">Status</FormLabel>
                        <RadioGroup
                          row
                          aria-label="payment_mode"
                          name="status"
                          value={values.status}
                          id="payment_mode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value={"1"}
                            control={<Radio color="primary" />}
                            label="All"
                          />
                          <FormControlLabel
                            value={"2"}
                            control={<Radio color="primary" />}
                            label="Paid"
                          />
                          <FormControlLabel
                            value={"3"}
                            control={<Radio color="primary" />}
                            label="Unpaid"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {touched.spa_type && errors.spa_type}
                        </FormHelperText>
                      </FormControl>
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        color="primary"
                        disabled={
                          values.client === "" || values.invoiceType === ""
                        }
                        className={classes.popDownBtn}
                        size="medium"
                        onClick={() => getOrders(values)}
                        variant="contained"
                      >
                        {loading === false ? (
                          <> {"Get order list"}</>
                        ) : (
                          <>
                            <img
                              alt="loader"
                              src={loadingSpinner}
                              className={classes.buttonProgress}
                            />{" "}
                            Get order list{" "}
                          </>
                        )}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal open={openSessionExpiredModal} />
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <ModalCentered
        title={"Preview"}
        open={preview}
        toggle={() => setPreview(false)}
        // maxWidth="md"
        fullScreen="true"
        children={
          <Box sx={{ textAlign: "center" }}>
            {preview.type ? (
              <iframe
                title="iframe"
                style={{ width: "100%", height: "calc(100vh - 100px)" }}
                src={preview.url}
              />
            ) : (
              <ImageRotate
                alt="cool image"
                type="users"
                image={preview && preview.url}
              />
            )}
          </Box>
        }
      />
    </StyledContainer>
  );
};

export default BulkUpdateCommissionReport;
