import React, {  useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import {  Button, Typography } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { NavLink } from 'react-router-dom';
import ModalCentered from 'src/components/Modals/ModalCentered'
import InventoryPriceForm from './InventoryPriceForm'
import clsx from 'clsx';
import SnackBar from "src/components/SnackBar/SnackBar"
import NormalCard from "src/components/Cards/NormalCard"
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { inventoryPriceService } from 'src/_services/inventoryPrice'
import moment from 'moment'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import { CloudDownload, InfoOutlined } from '@mui/icons-material';
import CopytoClipboard from 'src/components/ButtonComponents/CopytoClipboard';
import BulkUpload from './BulkUpload';
import GetMuiTheme from 'src/components/theme';
const PREFIX = 'InventoryAndPriceItems';

const classes = {
    tableEllipsCompany: `${PREFIX}-tableEllipsCompany`,
    root: `${PREFIX}-root`,
    tooltip: `${PREFIX}-tooltip`,
    green: `${PREFIX}-green`,
    gray: `${PREFIX}-gray`,
    container: `${PREFIX}-container`,
    editdeletbtn: `${PREFIX}-editdeletbtn`,
    btnsdltedt: `${PREFIX}-btnsdltedt`,
    footerbtn: `${PREFIX}-footerbtn`,
    footerbtnrit: `${PREFIX}-footerbtnrit`,
    iconbtn: `${PREFIX}-iconbtn`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.tableEllipsCompany}`]: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: '11rem',
        [theme.breakpoints.down('sm')]: {
            width: '8rem',
        },
        [theme.breakpoints.up('sm')]: {
            width: '11rem',
        },
    },

    [`& .${classes.root}`]: {
        margin:'1rem',
        '& .MuiTableHead-root': {
            background: 'blue',
            padding:'1rem'
        }
    },

    [`& .${classes.tooltip}`]: {
        cursor:'copy'
    },

    [`& .${classes.green}`]: {
        color: '#22ec22',
        width:'1rem'
    },

    [`& .${classes.gray}`]: {
        color: 'gray',
        width:'1rem'
    },

    [`& .${classes.container}`]: {
        padding: '24px'
    },

    [`& .${classes.editdeletbtn}`]: {
        color: '#3363cb'
    },

    [`& .${classes.btnsdltedt}`]: {
        marginRight: theme.spacing(1)
    },

    [`& .${classes.footerbtn}`]: {
        textAlign: 'right',
        width: '95%',
        margin: 'auto',
        marginBottom:theme.spacing(4),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down(453)]: {
            textAlign: 'center',
        },
    },

    [`& .${classes.footerbtnrit}`]: {
        marginLeft: theme.spacing(2),
        // marginBottom:theme.spacing(2),
        [theme.breakpoints.down(453)]: {
            marginLeft: theme.spacing(0),
            marginTop: theme.spacing(2),
        },
    },

    [`& .${classes.iconbtn}`]: {
        padding: theme.spacing(0.8)
    }
}));

// const settings = require('../../settings');
const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});

const getMuiTheme =GetMuiTheme 

const InventoryAndPriceItems = ({userRole}) => {
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("");
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [show, setShow] = useState(false)
    const [inventoryPriceData, setInventoryPriceData] = useState([]);
    const [rowDataId, setRowDataId] = useState([]);
    const [editId, setEditId] = useState([]);
    const [loading, setLoading] = useState(true);

    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
    const navigate = useNavigate();
    const [csvData, setCsvData] = React.useState([]);
    const [bulkUploadDilaog,setBulkUploadDilaog] = React.useState(false)

    function sortByDate(a, b) {
        if (a.date < b.date) {
            return 1;
        }
        if (a.date > b.date) {
            return -1;
        }
        return 0;
    }

    

    const getPriceData =(()=>{
        inventoryPriceService.getInventoryPrice()
        .then(res => {
            if (res) {
                if (res.status === 200) {
                    setLoading(false)
                    let array = []
                    setInventoryPriceData(res.data)
                    res.data.map((item)=>{
                        return(
                            array.push({
                                date:moment(item.last_updated).format("YYYY-MM-DD"),
                                security_id:item.security_id,
                                Name:item.name,
                                Isin:item.isin,
                                quantity:item.quantity,
                                Status:item.is_sold_online,
                                price:item.price,
                                "Price 0.5%":parseFloat(Number(item.price) + (Number(item.price) * 0.5/ 100)).toFixed(3),
                                "Price 1%":parseFloat(Number(item.price) + (Number(item.price) * 1 / 100)).toFixed(3),
                                "Price 2%":parseFloat(Number(item.price) + (Number(item.price) * 2 / 100)).toFixed(3),
                                "Price 3%":parseFloat(Number(item.price) + (Number(item.price) * 3 / 100)).toFixed(3),
                                "Price 4%":parseFloat(Number(item.price) + (Number(item.price) * 4 / 100)).toFixed(3),
                                "Price 5%":parseFloat(Number(item.price) + (Number(item.price) * 5 / 100)).toFixed(3),
                            })
                        )
                    })
                    const data =array.filter((item) =>  item.date !== 'Invalid date').sort(sortByDate)
                    setCsvData(data)
                } else {
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setLoading(false)
                        setOpenSessionExpiredModal(true)
                    }
                }
            }
        })
        return
    })
    useEffect(()=>{
        getPriceData()
    },[])


    const handleRowClick = (rowData, rowMeta) => {

        let rowId = rowMeta.map(item => {
            return inventoryPriceData[item.dataIndex].security_id
        })

        setRowDataId(rowId)
    };

    const handleInternalTeam = () => {
        let inventoryType = {
            security_id: rowDataId,
            mail_type: "Inventory"
        }
        inventoryPriceService.postInternalTeam(inventoryType).then(res => {
            if (res) {
                if (res.status === 200) {
                    if (res.data === "MAIL_SENT") {
                        setShow(false)
                        setSeverity("success")
                        setSnackbarTitle("Mail Sent Successfully")
                        setOpen(true);
                    }

                } else {
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setShow(false)
                        setOpenSessionExpiredModal(true)
                    } else {
                        setShow(false)
                        setSeverity("error")
                        setSnackbarTitle("Failed to send a mail")
                        setOpen(true);
                    }


                }

            }
        })
    }

    const handleUpdateWithSameData = () => {
        let inventoryType = {
            InventoryId: rowDataId,
        }
        inventoryPriceService.postSamedata(inventoryType).then(res => {
            if (res) {
                if (res.status === 200) {
                    getPriceData()
                    setRowDataId([])
                    setShow(false)
                    setSeverity("success")
                    setSnackbarTitle("Updated Successfully")
                    setOpen(true);
                } else {
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setShow(false)
                        setOpenSessionExpiredModal(true)
                    }
                }
            }
        })

    }

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleShow = () => {
        setShow(true);
    }

    const handleShowClose = () => {
        setShow(false);
        setBulkUploadDilaog(false)
    }

    const handleBulkPriceUpdate=((values)=>{
        setLoading(true)
        inventoryPriceService.bulkPriceUpdate(values).then(res => {
            if (res) {
            setLoading(false)
                        setBulkUploadDilaog(false)

                if (res.status === 200) {
                    setBulkUploadDilaog(false)
                    setSeverity("success")
                    setSnackbarTitle("Inventory And Price Updated Successfully")
                    setOpen(true);
                    getPriceData()
                } else {
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setBulkUploadDilaog(false)
                        setOpenSessionExpiredModal(true)
                    }else{
                    setSeverity("error")
                    setSnackbarTitle("Failed to update")
                    setOpen(true);
                    }
                }
            }
        })
    })

    const submitHandle = (values) => {
        let putValue = {
            vendor: JSON.parse(localStorage.getItem('DefaultUnlistedAccount'))[0].code,
            price: values.price,
            quantity: parseInt(values.quantity),
            owned_by: JSON.parse(localStorage.getItem('DefaultUnlistedAccount'))[0].code,
            security_id: editId,
            minimum_quantity_to_buy: parseInt(values.min_quantity),
            is_sold_online: values.is_sold_online,
            updatedValues:values.updatedValues
        }
        inventoryPriceService.putInventory(putValue).then(res => {
            if (res) {
                if (res.status === 200) {
                    setShow(false)
                    setSeverity("success")
                    setSnackbarTitle("Inventory And Price Updated Successfully")
                    setOpen(true);
                    getPriceData()
                } else {
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setShow(false)
                        setOpenSessionExpiredModal(true)
                    }
                }
            }
        })
    }

    const handleEditTableRow = (id) => {
        setEditId(id);
        handleShow();
    }

    const navigatetoPriceTrend = (id) => {
        navigate(`/app/security/price-trend/${id}`,
            {
                replace: true,
                state: { tabvalue: 3 }
            }

        )
    }
    const [hover, setHovers] = useState(false);
    const [rowindex, setrowIndex] = React.useState('');

    const onHover = () => {
        setHovers(true);
    };


    const columns = [
        {
            name: 'security_id',
            label: "Scrip ID",
            options: {
                display: false
            }
        },
        {
            name: 'vendor_id',
            label: "Vendor ID",
            options: {
                display: false
            }
        },
        {
            name: "name",
            label: "Scrip Name",
            options: {
                hint: "Name of the scrip",
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const name = value && value.length > 35 ? value.substr(0, 35) + "..." : value;
                    return (
                        <div
                        onMouseEnter={() => {
                            onHover()
                            setrowIndex(tableMeta.rowData[0])
                          }}
                          onMouseLeave={()=>  setrowIndex()} 
                          style={{ display: "flex", width:'13rem' }} >
                                    <Typography
                                        variant='body2'
                                        component={LinkBtn}
                                        noWrap
                                          className={classes.formgrgroup}
                                          to={`/app/security-details/${tableMeta.rowData[0]}`}
                                        color="primary"
                                        style={{ textTransform: "uppercase" }}
                                    >
                                        {name}
                                    </Typography>
                                {rowindex ===tableMeta.rowData[0] &&(
                                    <Tooltip style={{cursor:'copy'}} title={hover ? "Copied" :"Click to Copy"}>
                                <CopytoClipboard userBasicInfo={value} state={hover}/> 
                            </Tooltip>
                            )}

                                </div>
                    );
                },
            }
        },
        {
            name: 'isin',
            label: "ISIN",
            options: {
                display: false
            }
        },
        // {
        //     name: "parent_company_name",
        //     label: "Company",
        //     options: {
        //         hint: "Scrip Associated Company",
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (value) => {
        //             return (
        //                 <div className={classes.tableEllipsCompany} >
        //                     <Typography
        //                         variant='body2'
        //                         noWrap
        //                         style={{ textTransform: "capitalize" }}
        //                     >
        //                         {value}
        //                     </Typography>
        //                 </div>
        //             )
        //         }
        //     }
        // },
        // {
        //     name: "vendor",
        //     label: "Vendor",
        //     options: {
        //         hint: "Source Channel Partner who has the Inventory",
        //         filter: false,
        //         sort: false,
        //         customBodyRender: (value, tableMeta) => {
        //             return (
        //                 <Tooltip interactive title={value} disableHoverListener={!hoverStatus}>
        //                     <div
        //                         className={classes.tableEllips}
        //                         ref={textElementRef}
        //                         style={{
        //                             whiteSpace: 'nowrap',
        //                             overflow: 'hidden',
        //                         }}
        //                     >
        //                         <Typography
        //                             variant='body2'
        //                             component={LinkBtn}
        //                             noWrap
        //                             to={`/app/user-details/${tableMeta.rowData[1]}`}
        //                             color="primary"
        //                             style={{ textTransform: "capitalize" }}
        //                         >
        //                             {value}
        //                         </Typography>
        //                     </div>
        //                 </Tooltip>
        //             )
        //         }
        //     }
        // },
        {
            name: "price",
            label: "Price",
            options: {
                hint: "Current Share Price",
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    const validPrice = parseFloat(value)
                    return (
                        value && <Typography>
                            {validPrice.toFixed(2)}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "is_sold_online",
            label: "Online",
            options: {
                hint: "Sold status",
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        < FiberManualRecordIcon className={clsx({
                            [classes.green]: value === true,
                            [classes.gray]: value === false || value === null,
                        })} />
                    )
                }
            }
        },
        {
            name: "quantity",
            label: "Quantity",
            options: {
                hint: "Current Quantity of Available Shares",
                filter: false,
                sort: false,
            }
        },

        {
            name: "minimum_quantity_to_buy",
            label: "Min Quantity",
            options: {
                hint: "Minimum Quantity to BUY",
                filter: false,
                sort: false,
                display: false
            }
        },

        // {
        //     name: "owned_by",
        //     label: "Owned BY",
        //     options: {
        //         hint: 'Internal RM who owns the Folios',
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (value) => {
        //             return (
        //                 <div className={classes.tableEllipsCompany} >
        //                     <Typography
        //                         variant='body2'
        //                         noWrap
        //                         style={{ textTransform: "capitalize" }}
        //                     >
        //                         {value}
        //                     </Typography>
        //                 </div>
        //             )
        //         }
        //     }
        // },
        {
            name: "last_updated",
            label: "Last Updated",
            options: {
                hint: "Last Update to the Inventory",
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    let valueFormate = moment(value).format("DD-MM-YYYY LTS")

                    if (valueFormate !== "Invalid date") {
                        return (
                            <div className={classes.tableEllipsCompany} >
                                <Typography
                                    variant='body2'
                                    noWrap
                                >
                                    {valueFormate}
                                </Typography>
                            </div>

                        )
                    }

                }
            }
        },
        {
            name: "",
            label: "Price Trend",
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <span className={classes.editdeletbtn}
                            onClick={() => navigatetoPriceTrend(tableMeta.rowData[0])} >
                            <IconButton className={classes.iconbtn}>
                                <InfoIcon />
                            </IconButton>
                        </span>
                    );
                }
            }
        },
        {
            name: "",
            label: "Action",
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <span className={classes.editdeletbtn} >
                            <IconButton onClick={() => handleEditTableRow(tableMeta.rowData[0])} className={classes.iconbtn} aria-label="edit" >
                                <EditIcon />
                            </IconButton>
                        </span>
                    );
                }
            }
        }
    ];



const downloadCSV = (array, filename) => {
    const headers = Object.keys(array[0]);
    const csvContent = array.reduce((csvString, row) => {
        const values = Object.values(row);
        const rowString = values.map(value => `"${value}"`).join(',');
        return csvString + rowString + '\n';
      }, headers.join(',') + '\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    downloadCSV(csvData, 'scrips.csv');
  };

  const dataColumns =(userRole==="ADMIN" || userRole==="OPERATION") ? columns: columns.slice(0, columns.length - 2)


  const handleUpload =(()=>{
  setBulkUploadDilaog(true)
  })
    return (
        <Root>
        <NormalCard title={
            <div style={{alignItems:'center',display:'flex'}}>
                Inventory and Price
                <Tooltip  title="Hidden scrips will not be displayed here">
                    <InfoOutlined style={{fontSize:'14px', marginLeft:'4px'}}/>
                </Tooltip>
            </div>}
        btn={(userRole==="ADMIN" || userRole==="OPERATION") ?
                <Button   className={classes.addButton}
                variant="contained"
                color="primary"
                size='medium'
                onClick={handleUpload}
                >
                Bulk price update
              </Button> : null
              }
            subtitle={"List of Scrips, Current Inventory and Price"}
        >
            <ThemeProvider theme={getMuiTheme}>
                <MUIDataTable
                    data={inventoryPriceData}
                    headerSort={false}
                    columns={dataColumns}
                    options={{
                        customToolbar: () => (
                           <Tooltip title="Download">
                             <IconButton disableRipple  onClick={handleDownload} filename="report.csv">
                               <CloudDownload style={{margin:'0px 8px -4px',fontSize:'2rem', color:'grey'}}/>
                            </IconButton>
                           </Tooltip>
                          ),
                        download:false,
                        viewColumns: false,
                        // download: true,
                        selectableRows: "none",
                        print: false,
                        // disableToolbarSelect: true,
                        delete: false,
                        rowsPerPage: 10,
                        rowsPerPageOptions: [5, 10, 25],
                        onRowSelectionChange: handleRowClick,
                        customSearch: (searchQuery, currentRow, columns) => {
                            let isFound = false;
                            currentRow.forEach(col => {
                                if (col && col.toString().toLowerCase().indexOf(searchQuery.toLocaleLowerCase()) >= 0) {
                                    isFound = true;
                                }
                            });
                            return isFound;
                        },
                        textLabels: {
                            body: {
                                noMatch: loading ?
                                    'Loading...' : 'Sorry, there are no matching data to display'
                            },
                        },
                        sortOrder: {
                            name: 'last_updated',
                            direction: 'desc'
                        },
                    }}
                />
            </ThemeProvider>
            {(userRole === "OPERATION" || userRole === "ADMIN") &&<div className={classes.footerbtn}>
                <Button
                    disabled={rowDataId.length === 0}
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={handleUpdateWithSameData}>
                    Update With Same Data
                </Button>
                <Button className={classes.footerbtnrit}
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={handleInternalTeam}
                >
                    Publish To  Internal Team
                </Button>
            </div>}
            <ModalCentered
                title="Inventory and Price"
                open={show}
                toggle={handleShowClose}
                children={(<InventoryPriceForm formSubmit={submitHandle} id={editId} />)}
            />
              <ModalCentered
                title="Bulk Upload"
                open={bulkUploadDilaog}
                toggle={handleShowClose}
                children={(<BulkUpload loading={loading} formSubmit={handleBulkPriceUpdate}  />)}
            />
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />

<SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
    />
        </NormalCard>
        </Root>
    );
}

export default InventoryAndPriceItems
