import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { Formik } from "formik";
import { Grid, Container, TextField, RadioGroup, Radio, Typography, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
const PREFIX = 'TcsOrTdsModal';

const classes = {
  container: `${PREFIX}-container`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  inputMargin: `${PREFIX}-inputMargin`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    margin: 'auto',
    marginTop: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "20%",
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  }
}));

export default function TcsOrTdsModal(props) {

  const [tcsOrTdsTotal, setTcsOrTdsTotal] = useState(0)
  useEffect(() => {
    setTcsOrTdsTotal(parseFloat(props.applicableSum * (props.data.tcsOrTdsPercentage /100)))
  }, [])

  return (
    (<Root>
      <Formik
        initialValues={props.data}
        onSubmit={(values) => {
          if (values.taxType === "TCS") {
            values.TCS = tcsOrTdsTotal
            values.TDS = 0
          } else {
            values.TDS = tcsOrTdsTotal
            values.TCS = 0
          }

          props.formSubmit(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Container className={classes.container}>
              <Grid container >
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <RadioGroup row
                      aria-label=""
                      name=""
                      defaultValue={values.taxType}
                      onChange={(e) => {
                        setFieldValue('taxType',e.target.value)

                      }}
                    >
                          <FormControlLabel
                            value={"TCS"}
                           disabled={props.taxType}
                            control={<Radio />}
                            label="TCS"
                          />
                          <FormControlLabel
                            value={"TDS"}
                            disabled={props.taxType}
                            control={<Radio />}
                            label="TDS"
                          />
                    </RadioGroup>
                    </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>


                    <TextField
                    fullWidth
                    variant='outlined'
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">{values.taxType ==="TCS" ? " % TCS" : "% TDS"}</InputAdornment>,
                      }}
                      value={values.tcsOrTdsPercentage}
                      onChange={(e) => {
                        setFieldValue('tcsOrTdsPercentage',e.target.value)
                        setTcsOrTdsTotal(parseFloat(props.applicableSum) * parseFloat(e.target.value * 0.01))
                      }}>

                    </TextField>



                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} mt={1}
                  style={{textAlign:'center', border:'1px solid lightgrey' , padding:'15px'}}
                  >
                    {/* <TextField value={tcsOrTdsTotal} onChange={(e)=>{
                      setTcsOrTdsTotal(e.target.value)
                     }}>
                     </TextField> */}

                    <Typography
                      variant="body"
                      noWrap={false}
                      color="primary"
                    >
                      Amount : ₹{Number.isNaN(tcsOrTdsTotal) ? "" : tcsOrTdsTotal.toFixed(2)}
                    </Typography>

                  </Grid>
                </Grid>
              <Button
                color="primary"
                className={classes.popDownBtn}
                size="medium"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Add
              </Button>
            </Container>
          </form>
        )}
      </Formik></Root>)
  );
}
